import React from 'react'
import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    row: {
        marginBottom: theme.spacing(2),
    }
}));

function DeliveryTab({ deliveryTab, changeDeliveryTab }) {

    const classes = useStyles();
    
    const handleChange = e => {
        changeDeliveryTab(e.target.name, e.target.value);
    }

    const validate = (_var) => !(!!_var && _var !== '') ? 'Ce champ est requis' : undefined;
    
    return (
        <div>
            <Grid className={classes.row} container spacing={2}>
                <Grid item sm={4} xs={12}>
                    <TextField
                        variant='outlined'
                        label='Lieu de livraison'
                        required
                        fullWidth
                        color='secondary'
                        value={deliveryTab.LIEULIVSOUHAIT}
                        name={'LIEULIVSOUHAIT'}
                        helperText={validate(deliveryTab.LIEULIVSOUHAIT)}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item sm={8} xs={12}>
                    <TextField
                        variant='outlined'
                        label='Adresse (1)'
                        // required
                        fullWidth
                        color='secondary'
                        value={deliveryTab.ADLIV1}
                        name={'ADLIV1'}
                        // helperText={validate(deliveryTab.ADLIV1)}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item sm={12} xs={12}>
                    <TextField
                        variant='outlined'
                        label='Adresse (2)'
                        fullWidth
                        color='secondary'
                        value={deliveryTab.ADLIV2}
                        name={'ADLIV2'}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <TextField
                        variant='outlined'
                        label='Code postal'
                        // required
                        fullWidth
                        color='secondary'
                        value={deliveryTab.CPLIV}
                        name={'CPLIV'}
                        // helperText={validate(deliveryTab.CPLIV)}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item sm={8} xs={12}>
                    <TextField
                        variant='outlined'
                        label='Ville'
                        // required
                        fullWidth
                        color='secondary'
                        value={deliveryTab.VILLIV}
                        name={'VILLIV'}
                        // helperText={validate(deliveryTab.VILLIV)}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <TextField
                        variant='outlined'
                        type='date'
                        label='Date de livraison souhaitée'
                        inputProps={{
                            min: moment().format('YYYY-MM-DD'),
                            // defaultValue: moment().format('YYYY-MM-DD'),
                        }}
                        // required
                        fullWidth
                        color='secondary'
                        value={deliveryTab.DTELIVSOUHAIT}
                        name={'DTELIVSOUHAIT'}
                        // helperText={validate(deliveryTab.DTELIVSOUHAIT)}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default DeliveryTab;
