import React from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import useAuth from '../components/Auth/useAuth';
import { navigate } from '../routes';

function Notfound() {
    const [{user}] = useAuth();
    return (
        <Box p={3} minHeight='100vh' display='flex' justifyContent='center' alignItems='center'>
            <Box textAlign='center'>
                <Typography variant='h1'>
                    404
                </Typography>
                <Typography variant='h6'>
                    Cette page n'existe pas.
                </Typography>
                <Box my={2}>
                    <Button variant='contained' color='secondary' onClick={() => navigate.push('OrdersPage')} fullWidth>
                        {user ? 'Retour aux commandes' : 'Se connecter'}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

export default Notfound;