import * as actions from "./actions";
import { useSelector, useDispatch } from "react-redux";
import { request } from "../../request";
import confs from "../../confs";

const { URL_API } = confs;

function useOrderForm() {
  const orderForm = useSelector((state) => state.orderForm);
  const dispatch = useDispatch();

  const methods = {
    selectCollection: (collection) =>
      dispatch({ type: actions.SELECT_COLLECTION, payload: collection }),
    selectProduct: (product) =>
      dispatch({ type: actions.SELECT_PRODUCT, payload: product }),
    selectModel: (model) =>
      dispatch({ type: actions.SELECT_MODEL, payload: model }),
    selectStandardOptions: (options) =>
      dispatch({ type: actions.SELECT_STANDARD_OPTIONS, payload: options }),
    selectStandardOption: (option) =>
      dispatch({ type: actions.SELECT_STANDARD_OPTION, payload: option }),
    selectAdditionalOptions: (options) =>
      dispatch({ type: actions.SELECT_ADDITIONAL_OPTIONS, payload: options }),
    selectAdditionalOption: (option, options) =>
      dispatch({
        type: actions.SELECT_ADDITIONAL_OPTION,
        payload: { option, options },
      }),
    setArticle: (article) =>
      dispatch({ type: actions.SET_ARTICLE, payload: article }),
    prefillOrderInformation: (client) =>
      dispatch({ type: actions.PREFILL_ORDER_INFORMATION, payload: client }),
    changeDeliveryTab: (key, value) =>
      dispatch({ type: actions.CHANGE_DELIVERY_TAB, payload: { key, value } }),
    changeClientTab: (key, value) =>
      dispatch({ type: actions.CHANGE_CLIENT_TAB, payload: { key, value } }),
    changeCommentTab: (key, value) =>
      dispatch({ type: actions.CHANGE_COMMENT_TAB, payload: { key, value } }),
    changeDestination: (value) =>
      dispatch({ type: actions.CHANGE_DESTINATION, payload: value }),
    saveOrder: (order, cb) =>
      request
        .post(`${URL_API}/orders/generate`, order)
        .then((data) => {
          if (data.success && cb) return cb(null, data.payload);

          throw new Error("Request has failed");
        })
        .catch((err) => cb && cb(err, null)),
  };

  return [orderForm, methods];
}

export default useOrderForm;
