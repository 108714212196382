import React from 'react'
import { Box, makeStyles, Typography, Container } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#eee',
        borderBottom: '.5rem solid #5D6F7A',
    },
}));

function SubHeader({ children }) {
    const classes = useStyles();
    
    return (
        <Box px={2} py={4} mt={-2} className={classes.root}>
            <Container maxWidth='lg'>
                <Typography variant='h6'>
                    {children}
                </Typography>
            </Container>
        </Box>
    );
}

export default SubHeader
