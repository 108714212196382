import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import Reducers from './reducers.js';
/* Redux thunk middleware */
const middleware = [thunk];

export const SESSION_STATE_KEY = 'app.pwa.83EgbdHKlI84nZsd7etRp';

export const initializeStore = () => {
  const initialState = JSON.parse(localStorage.getItem(SESSION_STATE_KEY))||{};
  
  const store = createStore(
    Reducers,
    initialState,
    getMiddleWare()
  );

  store.subscribe(() => {
    localStorage.setItem(SESSION_STATE_KEY, JSON.stringify(store.getState()));
  });
  
  return store;
  
};

function getMiddleWare() {
  if ((!process.env.NODE_ENV || process.env.NODE_ENV === 'development') /*&& !isMobile()*/) {
    return compose(
        applyMiddleware(
            ...middleware),
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
      );
  }else {
      return applyMiddleware(...middleware);
  }
}