import React from 'react';
import { Redirect } from 'react-router-dom';
// import Pages
import Notfound from '../pages/Notfound';
import HomePage from '../pages/HomePage';
import LoginPage from '../pages/LoginPage';
import OrdersPage from '../pages/OrdersPage';
import CreateOrderPage from '../pages/CreateOrderPage';
import OrderPage from '../pages/OrderPage';

// Routes
export default [
    // {
    //     name: 'HomePage',
    //     path: '/',
    //     exact: true,
    //     private: true,
    //     component: HomePage,
    // },
    {
        name: 'OrdersPage',
        path: '/',
        exact: true,
        private: true,
        component: OrdersPage,
    },
    {
        name: 'CreateOrderPage',
        path: '/orders/new',
        exact: true,
        private: true,
        component: CreateOrderPage,
    },
    {
        name: 'OrderPage',
        path: '/orders/:numcde',
        exact: true,
        private: true,
        component: OrderPage,
    },
    {
        name: 'LoginPage',
        path: '/login',
        exact: true,
        private: false,
        component: LoginPage,
    },
    {
        name: 'NotFoundPage',
        path: '/404',
        exact: true,
        private: false,
        component: Notfound,
    },
    {
        name: 'Default',
        render: () => <Redirect to='/404' />,
        private: false,
        exact: true,
    }
];