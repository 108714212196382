import React, { useState, useEffect } from 'react'
import SubHeader from '../SubHeader';
import { Box, Typography, Container, Grid, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import confs from '../../confs';
import { request } from '../../request';
import Loader from '../Loader';
import FooterStep from './FooterStep';
import EmptyData from './EmptyData';
import { navigate } from '../../routes';

const { URL_API } = confs;

function ProductStep({ orderForm, nextStep, selectProduct, prevStep }) {

    const [isFetching, setIsFetching] = useState(true);
    const [products, setProducts] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {

        setIsFetching(true);
        const fail = () => {
            setIsFetching(false);
            enqueueSnackbar('Erreur au chargement de la donnée', { variant: 'error' });
        };

        request.get(`${URL_API}/products`, { collection: orderForm.collection }).then(res => {
            if (res.success){
                setProducts(res.payload);
                setIsFetching(false);
            } else {
                throw new Error('');
            }
        }).catch(err => fail());
        
    }, []);

    const onSelectProduct = (product) => {
        selectProduct(product);
        nextStep();
    }
    
    return (
        <React.Fragment>
            <Box mt={-2}>
                <SubHeader>
                    Séléctionner un produit
                </SubHeader>
            </Box>
            <Box my={2}>

                {isFetching && (
                    <Loader />
                )}

                {(!isFetching && products.length === 0) && (
                    <EmptyData text="Il n'y a rien à afficher" button={{
                        label: 'Précédent',
                        action: prevStep,
                    }} />
                )}

                <Products 
                    products={products}
                    onSelectProduct={onSelectProduct}
                />

            </Box>

            <FooterStep prevStep={prevStep} />
        </React.Fragment>
    )
}

const useProductStyles = makeStyles({
    root: {
        border: '3px solid #ccc',
        borderRadius: '.25rem',
        cursor: 'pointer',
        height: '100%',
    },
    img: {
        width: '100%',
        height: 'auto',
    },
});

const Products = ({ products, onSelectProduct }) => {

    const classes = useProductStyles();
    
    return (
        <Container maxWidth='lg'>
            <Box mb={12}>
                {products.map(product => (
                    <Box mb={4}>
                        <Typography variant="h6" color='secondary'>{product.LIBFAMART}</Typography>
                        <Grid container spacing={2}>
                            {(product.products.map(prod => (
                                <Grid item lg={2} md={3} sm={6} xs={12}>
                                    <Box 
                                        className={classes.root} 
                                        px={1} py={2}
                                        onClick={() => onSelectProduct(prod.ADRIA_model_equipement)}
                                    >
                                        <img src={prod.ADRIA_image} className={classes.img} />
                                        <Typography align='center'>
                                            {prod.LIBSFA}
                                        </Typography>
                                    </Box>
                                </Grid>
                            )))}
                        </Grid>
                    </Box>
                ))}
            </Box>
        </Container>
    );
}

export default ProductStep
