export const SELECT_COLLECTION = "@createOrder/selectCollection";
export const SELECT_PRODUCT = "@createOrder/selectProduct";
export const SELECT_MODEL = "@createOrder/selectModel";
export const SELECT_STANDARD_OPTIONS = "@createOrder/selectStandardOptions";
export const SELECT_STANDARD_OPTION = "@createOrder/selectStandardOption";
export const SELECT_ADDITIONAL_OPTIONS = "@createOrder/selectAdditionalOptions";
export const SELECT_ADDITIONAL_OPTION = "@createOrder/selectAdditionalOption";
export const SET_ARTICLE = "@createOrder/setArticle";
export const PREFILL_ORDER_INFORMATION = "@createOrder/prefillOrderInformation";
export const CHANGE_DELIVERY_TAB = "@createOrder/changeDeliveryTab";
export const CHANGE_CLIENT_TAB = "@createOrder/changeClientTab";
export const CHANGE_COMMENT_TAB = "@createOrder/changeCommentTab";
export const SAVE_ORDER = "@createOrder/saveOrder";
export const CHANGE_DESTINATION = "@createOrder/changeDestination";
