import React from 'react'
import { Box, Typography, Button } from '@material-ui/core'
import { navigate } from '../../routes';

function EmptyData({ button = { label: 'Précédent', action: () => alert('Ok') }, text = 'Aucune données à afficher'}) {
    return (
        <Box p={3} display='flex' justifyContent='center' alignItems='center'>
            <Box textAlign='center'>
                <Typography variant='h6'>
                    {text}
                </Typography>
                <Box my={2} textAlign='center'>
                    <Button variant='contained' color='secondary' onClick={button.action}>
                        {button.label}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default EmptyData;
