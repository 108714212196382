import React, { useEffect } from 'react'
import Header from '../components/Header'
import { navigate } from '../routes';
import CollectionStep from '../components/CreateOrderForm/CollectionStep';
import useOrderForm from '../components/CreateOrderForm/useOrderForm';
import ProductStep from '../components/CreateOrderForm/ProductStep';
import ModelStep from '../components/CreateOrderForm/ModelStep';
import StandardOptionsStep from '../components/CreateOrderForm/StandardOptionsStep';
import AdditionalOptionsStep from '../components/CreateOrderForm/AdditionalOptionsStep';
import OrderInformationStep from '../components/CreateOrderForm/OrderInformationStep';

const steps = [
    'collection',
    'product',
    'model',
    'standard-options',
    'additional-options',
    'order-information',
];

function CreateOrderPage({ currentUser }) {

    const step = window.location.hash.replace('#', '');
    const stepIndex = steps.findIndex(_step => _step === step);
    const [orderForm, { 
        selectCollection, 
        selectProduct, 
        selectModel, 
        selectStandardOptions,
        selectStandardOption,
        selectAdditionalOptions,
        selectAdditionalOption,
        setArticle,
        prefillOrderInformation
    }] = useOrderForm();

    useEffect(() => {
        if (stepIndex < 0) {
            // 404, if hash doesnt fit any step
            navigate.replace('NotFoundPage');
        }
    }, [step]);

    const nextStep = () => {
        if (stepIndex === steps.length-1) {
            return;
        }

        navigate.replace('CreateOrderPage', {}, { hash: steps[stepIndex + 1] });
    }
    
    const prevStep = () => {
        if (stepIndex === 0) {
            return;
        }

        navigate.replace('CreateOrderPage', {}, { hash: steps[stepIndex - 1] });
    }

    const getStep = () => {
        switch(step) {
            case 'collection':
                return (
                    <CollectionStep 
                        nextStep={nextStep}
                        selectCollection={selectCollection}
                    />
                );
                break;
            case 'product':
                return (
                    <ProductStep
                        orderForm={orderForm}
                        nextStep={nextStep}
                        prevStep={prevStep}
                        selectProduct={selectProduct}
                    />
                )
                break;
            case 'model':
                return (
                    <ModelStep
                        orderForm={orderForm}
                        nextStep={nextStep}
                        prevStep={prevStep}
                        selectModel={selectModel}
                    />
                )
                break;
            case 'standard-options':
                return (
                    <StandardOptionsStep
                        orderForm={orderForm}
                        nextStep={nextStep}
                        prevStep={prevStep}
                        setArticle={setArticle}
                        selectOptions={selectStandardOptions}
                        selectOption={selectStandardOption}
                    />
                )
                break;
            case 'additional-options':
                return (
                    <AdditionalOptionsStep
                        orderForm={orderForm}
                        nextStep={nextStep}
                        prevStep={prevStep}
                        setArticle={setArticle}
                        selectOptions={selectAdditionalOptions}
                        selectOption={selectAdditionalOption}
                        prefillOrderInformation={prefillOrderInformation}
                    />
                )
                break;
            case 'order-information':
                return (
                    <OrderInformationStep
                        orderForm={orderForm}
                        nextStep={nextStep}
                        prevStep={prevStep}
                    />
                )
                break;
        }
    }

    return (
        <React.Fragment>
            <Header user={currentUser} menu={1} />
            {getStep()}
        </React.Fragment>
    )
}

export default CreateOrderPage;
