import React from 'react'
import useAuth from '../components/Auth/useAuth';
import { Box } from '@material-ui/core';
import Header from '../components/Header';

function HomePage() {

    const [{ user }, { logout }] = useAuth();
    
    return (
        <React.Fragment>
            <Header />
            <Box my={1}>
                HomePage
            </Box>
            <Box my={1}>
                
            </Box>
        </React.Fragment>
    );
}

export default HomePage
