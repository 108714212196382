// theme.js

const theme = {
    palette: {
        primary: {
            main: '#fff',
            contrastText: '#2A3942',
        },
        secondary: {
            main: '#2A3942',
            contrastText: '#fff',
        },
        // error: {
        //     main: '#E2001A',
        //     contrastText: '#fff'
        // },
    },
    typography: {
        fontFamily: [
            '"Roboto Condensed", sans serif',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    overrides: {
        MuiTableCell: {
            root: {
                borderRight: '1px solid rgba(0,0,0, .125)',
                fontSize: '.8rem',
                '&:last-child': {
                    borderRight: 'none',
                }
            }
        },
        MuiTableContainer: {
            root: {
                border: '1px solid rgba(0,0,0, .125)',
                borderRadius: '.3rem',
            }
        },
    }
};

export default theme;