
String.prototype.ucfirst = function() {
    return this.slice(0,1).toUpperCase() + this.slice(1).toLowerCase();
}

Array.prototype.findAndReplace = function(cb, item) {
    const index = this.findIndex(cb);

    if (!(index >= 0)) return this;

    this[index] = item;

    return this;
}

String.prototype.cleanDate = function() {
    return (this > '1900-01-01') ? this : '';
}