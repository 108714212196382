import React, { useEffect, useState } from 'react'
import { FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@material-ui/core';
import moment from 'moment';
import { request } from '../../request';
import confs from '../../confs';
import { useSnackbar } from 'notistack';

const { URL_API } = confs;


function Collections({ collection, changeCollection, disabled }) {

    const [collections, setCollections] = useState([]);
    const [fetching, isFetching] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        isFetching(true);
        request.get(`${URL_API}/collections?active=true`).then(data => {
            isFetching(false);
            if (!data.success) throw new Error('Erreur au chargement de la donnée. Réessayez plus tard');
            setCollections(data.payload);
            // Set default date
            const defaultCol = data.payload.find(c => {
                const now = moment();
                const startCol = moment(c.DTEDEB);
                const endCol = moment(c.DTEFIN);
                return (now.isAfter(startCol) && endCol.isAfter(now));
            });
            if (defaultCol && !collection) changeCollection(defaultCol.COLLECTION);
        }).catch(err => enqueueSnackbar(err, { variant: 'error' }));
    }, []);
    
    const handleChange = e => changeCollection(e.target.value);

    return (
        <FormControl fullWidth>
            <InputLabel shrink color='secondary' id="select-collections">Collection</InputLabel>
            <Select
                disabled={disabled}
                color='secondary'
                labelId="select-collections"
                value={collection}
                onChange={handleChange}
            >
                {/* <MenuItem value={'all'}>
                    {fetching && <CircularProgress color='secondary' size={12} />}
                    Toutes les collections
                </MenuItem> */}
                {
                    collections.map((col) => (
                        <MenuItem key={col.COLLECTION} value={col.COLLECTION}>{col.COLLECTION}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}

export default Collections
