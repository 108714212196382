import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Container,
  Collapse,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Button,
} from "@material-ui/core";
import SubHeader from "../SubHeader";
import confs from "../../confs";
import { request } from "../../request";
import { useSnackbar } from "notistack";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Loader from "../Loader";
import { makeStyles } from "@material-ui/styles";
import FooterStep from "./FooterStep";
import { ArrowForward as ArrowForwardIcon } from "@material-ui/icons";
import { price } from "../../utils";

const { URL_API } = confs;

const useStyles = makeStyles({
  nextButton: {
    backgroundColor: "#E2001A",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#f5384e",
    },
  },
});

function StandardOptionsStep({
  orderForm,
  nextStep,
  selectOptions,
  selectOption,
  prevStep,
  setArticle,
}) {
  const [isFetching, setIsFetching] = useState(true);
  const [options, setOptions] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const upperMD = useMediaQuery(theme.breakpoints.up("md"));

  const classes = useStyles();

  useEffect(() => {
    const fail = () => {
      enqueueSnackbar("Erreur au chargement de la donnée", {
        variant: "error",
      });
      setIsFetching(false);
    };

    setIsFetching(true);
    request
      .post(`${URL_API}/options/standard`, {
        collection: orderForm.collection,
        product: orderForm.product,
        model: orderForm.model,
      })
      .then((res) => {
        if (!res.success) return fail();

        setArticle(res.payload.article);
        const optionsByCateg = getOptionsByCateg(res.payload.options);
        setOptions(optionsByCateg);
        if (!orderForm.standardOptions)
          setDefaultOptions(optionsByCateg, res.payload.standardConfig);
        setIsFetching(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const getOptionsByCateg = (options) => {
    return options.reduce((acc, option) => {
      if (!(option.RENDERABLE === "D")) return acc;

      const index = acc.findIndex((it) => it.code === option.KATEG);
      if (index >= 0) {
        // We already have this KATEG
        acc[index].options.push(option);
      } else {
        acc.push({
          code: option.KATEG,
          name: option.KATE_NAZIV,
          options: [option],
        });
      }
      return acc;
    }, []);
  };

  const onSelectOption = (option) => {
    selectOption(option);
  };

  const setDefaultOptions = (optionsByCateg, standardConfig) => {
    const defaultOptions = optionsByCateg.map((categ) => {
      let defaultOption =
        standardConfig &&
        standardConfig[categ.code] &&
        categ.options.find(
          (o) => o.SIFRA === standardConfig[categ.code] && !(o.invalid === 1)
        );

      if (!defaultOption)
        defaultOption = categ.options.find(
          (option) => option.OBVEZNO === "D" && !(option.invalid === 1)
        );

      return {
        code: categ.code,
        name: categ.name,
        option: categ.options.length > 1 ? defaultOption : categ.options[0],
      };
    });
    selectOptions(defaultOptions);
  };

  return (
    <React.Fragment>
      <Box mt={-2}>
        <SubHeader>Séléctionner les options standards</SubHeader>
      </Box>
      <Box mt={2}>
        {isFetching ? (
          <Loader />
        ) : (
          <Container maxWidth="lg">
            <Grid container direction="row-reverse" spacing={4}>
              <Grid item xs>
                <Box
                  position={upperMD ? "sticky" : "relative"}
                  top={upperMD ? 65 : 0}
                >
                  <Collapse in={upperMD}>
                    <ArticleRecap
                      article={orderForm.article}
                      selectedOptions={orderForm.standardOptions}
                    />
                  </Collapse>
                </Box>
              </Grid>
              <Grid item md={8} xs={12}>
                <Options
                  options={options}
                  selectedOptions={orderForm.standardOptions}
                  onSelectOption={onSelectOption}
                />
              </Grid>
            </Grid>
          </Container>
        )}
      </Box>
      <FooterStep prevStep={prevStep}>
        <Button
          color="secondary"
          variant="contained"
          disabled={
            !(
              !isFetching &&
              !orderForm.standardOptions.find((it) => it.option === undefined)
            )
          }
          endIcon={<ArrowForwardIcon />}
          onClick={nextStep}
        >
          Suivant
        </Button>
      </FooterStep>
    </React.Fragment>
  );
}

const useArticleRecapStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid rgba(0,0,0,.125)",
    borderRadius: ".3rem",
  },
  ul: {
    padding: 0,
    margin: 0,
    width: "100%",
    listStyle: "none",
    fontSize: 12,
  },
  liHeader: {
    backgroundColor: "#dddfe2",
    color: "#464a4e",
    width: "100%",
    padding: theme.spacing(2),
  },
  li: {
    width: "100%",
    padding: theme.spacing(2),
  },
}));

export const ArticleRecap = ({ article, selectedOptions, image }) => {
  const classes = useArticleRecapStyles();

  return (
    <Box className={classes.root}>
      {Boolean(image) && (
        <Box p={2} display="flex" justifyContent="center">
          <img
            alt="Van"
            src={image}
            style={{ maxHeight: "150px", width: "auto" }}
          />
        </Box>
      )}
      <Box p={2}>
        <Typography variant="body" color="secondary">
          {article.ADRIA_code}
        </Typography>
        <Typography variant="h6" color="secondary">
          {article.LIBARTCON}
        </Typography>
        <Typography variant="body" color="secondary">
          {article.ADRIA_packages_variant} - {article.ADRIA_packages_name}
        </Typography>
      </Box>
      <ul className={classes.ul}>
        {selectedOptions.map((categ) => {
          if (categ.option) {
            return (
              <React.Fragment>
                <li className={classes.liHeader}>{categ.name}</li>
                <li className={classes.li}>
                  <code>{categ.option.SIFRA}</code>{" "}
                  {categ.option.LIBOPTCON || categ.option.NAZIV}
                </li>
              </React.Fragment>
            );
          } else if (categ.options && categ.options.length > 0) {
            return (
              <React.Fragment>
                <li className={classes.liHeader}>{categ.name}</li>
                {categ.options.map((option) => (
                  <li className={classes.li}>
                    <code>{option.SIFRA}</code>{" "}
                    {option.LIBOPTCON || option.NAZIV}
                  </li>
                ))}
              </React.Fragment>
            );
          }
        })}
      </ul>
    </Box>
  );
};

const useOptionStyles = makeStyles({
  headerCell: {
    backgroundColor: "#FDFDFE",
    fontSize: 16,
  },
  optionRow: {
    "&:hover": {
      backgroundColor: "rgba(0,0,0,.05)",
    },
    cursor: "pointer",
  },
  optionRowSelected: {
    backgroundColor: "rgba(0,0,0,.05)",
  },
});

const Options = ({ options, selectedOptions, onSelectOption }) => {
  const classes = useOptionStyles();

  return (
    <Box mb={8}>
      <TableContainer>
        <Table size="small">
          <TableBody>
            {options.map((categ) => (
              <React.Fragment>
                <TableRow>
                  <TableCell className={classes.headerCell} colSpan={4}>
                    {categ.name}
                  </TableCell>
                </TableRow>
                {categ.options.map((option) => {
                  const isSelected =
                    (
                      selectedOptions.find((c) => c.code === categ.code)
                        .option || {}
                    ).SIFRA === option.SIFRA;

                  const isInvalid = option.invalid === 1;

                  if (isInvalid) return null;

                  return (
                    <TableRow
                      key={option.SIFRA}
                      className={
                        isSelected
                          ? classes.optionRowSelected
                          : classes.optionRow
                      }
                      onClick={() =>
                        !isSelected && !isInvalid && onSelectOption(option)
                      }
                    >
                      <TableCell>
                        <input
                          disabled={isInvalid}
                          type="radio"
                          checked={isSelected && !isInvalid}
                        />
                      </TableCell>
                      <TableCell>{option.SIFRA}</TableCell>
                      <TableCell>{option.LIBOPTCON || option.NAZIV}</TableCell>
                      <TableCell>
                        {option.TARIFNETHT > 0 && price(option.TARIFNETHT || 0)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default StandardOptionsStep;
