import React, { useState, useEffect } from 'react'
import useAuth from './useAuth'
import { navigate } from '../../routes';
import { Box, TextField, Button, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ExitToApp as ExitToAppIcon } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import confs from '../../confs';

const { LOGO } = confs;

const useStyles = makeStyles(theme => ({
    form: {
        backgroundColor: '#fff',
        borderRadius: theme.shape.borderRadius,
        boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
        maxWidth: '400px',
    },
    image: {
        width: '300px',
        height: 'auto',
    },
    input: {
        marginBottom: theme.spacing(4),
    }
}));

function AuthForm() {

    const [{ user, token }, { login }] = useAuth();
    const [loging, isLoging] = useState(false);

    const [credentials, setCredentials] = useState({});
    const { enqueueSnackbar } = useSnackbar();

    const classes = useStyles();
    
    const onChange = e => setCredentials({...credentials, [e.target.name]: e.target.value});

    const onSubmit = (e, v) => {
        e.preventDefault();
        isLoging(true);
        // Call login from hook
        login(credentials, (err, res) => {
            if (!err) {
                // Success
                return navigate.push('OrdersPage');
            }
            isLoging(false);
            enqueueSnackbar('Erreur: Code ou mot de passe invalide', { variant: 'error' });
        });
    }

    useEffect(() => {
        // Redirect to dashboard if current user already logged in
        if (user && token) navigate.push('OrdersPage');
    }, []);

    useEffect(() => {
        // Redirect to dashboard if current user already logged in
        
	    // Create a new HTML5 EventSource
	    var source = new EventSource('http://localhost:80/events');

	    // Create a callback for when a new message is received.
	    source.onmessage = function(e) {

	        // Append the `data` attribute of the message to the DOM.
	        document.body.innerHTML += e.data + '<br>';
        };
        
        return () => {
            source.close();
        }
    }, []);
    
    return (
        <Box 
            display='flex' justifyContent='center' alignItems='center'
            width='100%' height='100vh'
            style={{backgroundColor: '#EEE'}}
        >
            <Box p={4} className={classes.form}>
                <Box mb={4} display='flex' justifyContent='center'>
                    <img src={LOGO} className={classes.image} />
                </Box>
                <form onSubmit={onSubmit}>
                    <TextField
                        variant='outlined'
                        label='Code'
                        required
                        fullWidth
                        color='secondary'
                        name={'code'}
                        className={classes.input}
                        onChange={onChange}
                    />
                    <TextField
                        variant='outlined'
                        label='Mot de passe'
                        required
                        fullWidth
                        color='secondary'
                        name='password'
                        type='password'
                        className={classes.input}
                        onChange={onChange}
                    />
                    <Button disabled={loging} startIcon={loging ? undefined : <ExitToAppIcon />} size='large' fullWidth type='submit' color='secondary' variant='contained' disableElevation>
                        {loging && <CircularProgress color='secondary' size={12} style={{marginRight: 10}} />}
                        Se connecter
                    </Button>
                </form>
            </Box>
        </Box>
    );
}

export default AuthForm
