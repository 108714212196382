import React from "react";
import { Container, Box } from "@material-ui/core";
import useOrders from "./useOrders";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useState } from "react";
import OrdersList from "./OrdersList";
import Filters from "../Filters/Filters";
import useFilters from "../Filters/useFilters";

function Orders() {
  const [{ orders }, { getMyOrders, putOrder, deleteOrder }] = useOrders();
  const [fetching, isFetching] = useState(true);
  const [onChangingCollection, setOnChangingCollection] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [filters, { changeCollection, changePeriod, changeWithDeliveredOrders }] = useFilters();

  
  useEffect(() => {
    // Get orders
    isFetching(true);

    getMyOrders({ collection: filters.collection, withDeliveredOrders: filters.withDeliveredOrders }, (err) => {
      isFetching(false);
      setOnChangingCollection(false);
      // Error case
      if (err)
        return enqueueSnackbar("Erreur au chargement des commandes", {
          variant: "error",
          autoHideDuration: 3000,
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.collection, filters.withDeliveredOrders]);

  const changeCol = (col) => {
    setOnChangingCollection(true);
    changeCollection(col);
  };

  
  return (
    <Container maxWidth="xl">
      <Box width="100%" display="flex" flexDirection="column">
        <Box>
          <Filters
            filters={filters}
            disabled={fetching}
            changeCollection={changeCol}
            changeWithDeliveredOrders={changeWithDeliveredOrders}
            orders={orders}
          />
        </Box>
        <Box flex={1}>
          {/* Orders list */}
          <OrdersList
            onChangingCollection={onChangingCollection}
            orders={orders}
            putOrder={putOrder}
            deleteOrder={deleteOrder}
            fetching={fetching}
          />
        </Box>
      </Box>
    </Container>
  );
}

export default Orders;
