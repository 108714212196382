import * as actions from './actions';
import moment from 'moment';

const initialState = {
    collection: null,
    withDeliveredOrders: 0,
};

export default function (state = initialState, action) {
    switch (action.type) {

        case actions.CHANGE_COLLECTION: 
            return {
                ...state,
                collection: action.payload,
            };
            break;
            
        case actions.CHANGE_PERIOD: 
            return {
                ...state,
                period: action.payload,
            };
            break;

        case actions.CHANGE_WITH_DELIVERED_ORDERS: 
            return {
                ...state,
                withDeliveredOrders: action.payload ? 1 : 0,
            };
            break;

        default:
            return state;
            break;
    }
}