import React, { useState, useEffect } from "react";
import SubHeader from "../SubHeader";
import {
  Box,
  Typography,
  Container,
  Grid,
  makeStyles,
  Divider,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import confs from "../../confs";
import { request } from "../../request";
import Loader from "../Loader";
import FooterStep from "./FooterStep";
import EmptyData from "./EmptyData";

const { URL_API } = confs;

function ModelStep({ orderForm, nextStep, selectModel, prevStep }) {
  const [isFetching, setIsFetching] = useState(true);
  const [models, setModels] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setIsFetching(true);
    const fail = () => {
      setIsFetching(false);
      enqueueSnackbar("Erreur au chargement de la donnée", {
        variant: "error",
      });
    };

    request
      .get(`${URL_API}/models`, {
        collection: orderForm.collection,
        product: orderForm.product,
      })
      .then((res) => {
        if (res.success) {
          setModels(res.payload);
          setIsFetching(false);
        } else {
          throw new Error("");
        }
      })
      .catch((err) => fail());
  }, []);

  const onSelectModel = (model) => {
    selectModel(model);
    nextStep();
  };

  return (
    <React.Fragment>
      <Box mt={-2}>
        <SubHeader>Séléctionner un model</SubHeader>
      </Box>
      <Box mt={2}>
        {isFetching && <Loader />}

        {!isFetching && models.length === 0 && (
          <EmptyData
            text="Il n'y a rien à afficher"
            button={{
              label: "Précédent",
              action: prevStep,
            }}
          />
        )}
        <Models models={models} onSelectModel={onSelectModel} />
      </Box>
      <FooterStep prevStep={prevStep} />
    </React.Fragment>
  );
}

const useModelStyles = makeStyles({
  root: {
    border: "3px solid #ccc",
    borderRadius: ".25rem",
    cursor: "pointer",
    height: "100%",
  },
});

const Models = ({ models, onSelectModel }) => {
  const classes = useModelStyles();
  console.log("models", models);

  return (
    <Container maxWidth="lg">
      <Box mb={12}>
        {models.map((model) => (
          <React.Fragment>
            <Box mb={4}>
              {Boolean(model.ADRIA_day_layout) && (
                <img
                  alt={model.LIBARTCON}
                  src={model.ADRIA_day_layout}
                  width="100%"
                  height="auto"
                  style={{ maxWidth: "150px" }}
                />
              )}
              <Typography variant="h6" color="secondary">
                {model.LIBARTCON}
              </Typography>
              <Grid container spacing={2}>
                {model.packages.map((pack) => (
                  <Grid item lg={2} md={2} sm={4} xs={12}>
                    <Box
                      className={classes.root}
                      px={1}
                      py={2}
                      onClick={() =>
                        onSelectModel({
                          code: pack.ADRIA_code,
                          variant: pack.ADRIA_packages_variant,
                          name: pack.LIBARTADRIA,
                        })
                      }
                    >
                      <Typography align="center">
                        {pack.ADRIA_packages_name}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box mb={2}>
              <Divider />
            </Box>
          </React.Fragment>
        ))}
      </Box>
    </Container>
  );
};

export default ModelStep;
