import React, { useEffect, useState } from 'react'
import useAuth from './useAuth'
import { navigate } from '../../routes';
import { Route } from 'react-router';
import { Box, CircularProgress } from '@material-ui/core';

function PrivateRoute (props) {

    const [{ user, token }, { confirm , logout}] = useAuth();
    const [confirming, isConfirming] = useState(true);

    useEffect(() => {
        // Confirm the token before rendering the page
        isConfirming(true);
        confirm((err, res) => {
            if (err) {
                logout();
                navigate.replace('LoginPage'); // Redirect to the login page
                return;
            }
            // Stop confirming
            isConfirming(false);
        })
    }, [ token ]);

    if (confirming) return <FullScreenLoader />; // TODO: Replace with a loader
    
    return <Route {...props} component={undefined} render={() => <props.component currentUser={user} />} />
}

const FullScreenLoader = () => (
    <Box width='100%' height='100vh' display='flex' justifyContent='center' alignItems='center'>
        <CircularProgress size={20} color='secondary' />
    </Box>
)

export default PrivateRoute
