export const REGISTER_SUCCESS = '@register/success';
export const REGISTER_FAILED = '@register/failed';

export const LOGIN_SUCCESS = '@login/success';
export const LOGIN_FAILED = '@login/failed';

export const LOGOUT = '@logout';

export const CONFIRM_SUCCESS = '@confirm/success';
export const CONFIRM_FAILED = '@confirm/failed';
