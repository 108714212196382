import * as actions from './actions';
import { useSelector, useDispatch } from "react-redux";
import sdk from "./sdk";
import { navigate } from '../../routes';

// Lets create our custom Hook that linked interface with state interractions
function useAuth() {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const methods = {

        login: (credentials, cb) => sdk.login(credentials).then(res => {
            if (res.success) {
                dispatch({ type: actions.LOGIN_SUCCESS, payload: res.payload });
                // Fire the cb
                if (cb) cb(null, res);
            } else {
                // Fire the cb
                if (cb) cb(res, null);
            }
        }),

        confirm: (cb) => sdk.confirm().then(res => {
            if (res.success) {
                dispatch({ type: actions.CONFIRM_SUCCESS, payload: res.payload });
                // Fire the cb
                if (cb) cb(null, res);
            } else {
                // Fire the cb
                if (cb) cb(res, null);
            }
        }),

        logout: () => {
            navigate.push('LoginPage');
            dispatch({ type: actions.LOGOUT })
        },
        
    };

    return [ auth, methods ];
    
}

export default useAuth;