import React from "react";
import Header from "../components/Header";
import Orders from "../components/Orders/Orders";

function OrdersPage({ currentUser }) {
  return (
    <React.Fragment>
      <Header user={currentUser} menu={0} />
      <Orders />
    </React.Fragment>
  );
}

export default OrdersPage;
