import * as actions from './actions';

const initialState = {
    orders: [],
};

export default function (state = initialState, action) {
    switch(action.type) {
        
        case actions.CGET_ORDERS_SUCCESS:
            return {
                ...state,
                orders: action.payload,
            };
            break;
       
        case actions.PUT_ORDER_SUCCESS:
            return {
                ...state,
                orders: state.orders.findAndReplace(o => o.NUMCDE === action.payload.NUMCDE, action.payload),
            };
            break;
       
        case actions.DELETE_ORDER_SUCCESS:
            return {
                ...state,
                orders: state.orders.filter(o => o.NUMCDE !== action.payload.NUMCDE),
            };
            break;
            
        default:
            return state;
    }
}