import React from 'react'
import { Box, Checkbox, FormControlLabel, FormLabel } from '@material-ui/core'
import Collections from './Collections';
import Periods from './Periods';

function Filters({ filters, changeCollection, disabled, changeWithDeliveredOrders }) {

    const { collection, withDeliveredOrders } = filters;
    
    return (
        <React.Fragment>
            <Box display='flex' alignItems='center'>
                <Box mr={4} minWidth={150}>
                    <Collections disabled={disabled} collection={collection} changeCollection={changeCollection} />
                </Box>      
                <Box>
                    <FormControlLabel onChange={() => changeWithDeliveredOrders(!withDeliveredOrders)} control={<Checkbox size='small' disableRipple  checked={Boolean(withDeliveredOrders)} />} label="Ajouter les commandes livrées dans la liste"/>
                </Box>
                {/* <Box minWidth={150}>
                    <Periods disabled={disabled} period={period} changePeriod={changePeriod} orders={orders} />
                </Box>       */}
            </Box>
        </React.Fragment>
    );
}

export default Filters
