import { combineReducers } from 'redux';
// Import reducers here
import authReducer from './components/Auth/reducer';
import ordersReducer from './components/Orders/reducer';
import filtersReducer from './components/Filters/reducer';
import orderFormReducer from './components/CreateOrderForm/reducer';

export default combineReducers({
    // Connect reducers to the store here
    auth: authReducer,
    orders: ordersReducer,
    filters: filtersReducer,
    orderForm: orderFormReducer,
});