import React from 'react'
import { Box, CircularProgress } from '@material-ui/core'

function Loader({ size, spacing }) {
    return (
        <Box p={spacing || 3} display='flex' justifyContent='center' alignItems='center'>
            <CircularProgress size={size || 24} color='secondary' />
        </Box>
    )
}

export default Loader
