import React from 'react'
import AuthForm from '../components/Auth/AuthForm'

function LoginPage() {
    return (
        <div>
            <AuthForm />
        </div>
    )
}

export default LoginPage
