import { useSelector, useDispatch } from 'react-redux';
import * as actions from './actions';

function useFilters() {

    const filters = useSelector(state => state.filters);
    const dispatch = useDispatch();
    
    const methods = {
        changeCollection: (collection) => dispatch({ type: actions.CHANGE_COLLECTION, payload: collection }),
        changePeriod: (period) => dispatch({ type: actions.CHANGE_PERIOD, payload: period }),
        changeWithDeliveredOrders: (withDeliveredOrders) => dispatch({ type: actions.CHANGE_WITH_DELIVERED_ORDERS, payload: withDeliveredOrders }),
    };
    
    return [filters, methods];
}

export default useFilters;
