import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Grid,
  Container,
  Collapse,
  Typography,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import SubHeader from "../SubHeader";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/styles";
import FooterStep from "./FooterStep";
import {
  ArrowForward as ArrowForwardIcon,
  Warning as WarningIcon,
} from "@material-ui/icons";
import { ArticleRecap } from "./StandardOptionsStep";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import DeliveryTab from "./DeliveryTab";
import useOrderForm from "./useOrderForm";
import ClientTab from "./ClientTab";
import CommentTab from "./CommentTab";
import useAuth from "../Auth/useAuth";
import qs from "querystring";
import { useSnackbar } from "notistack";
import { navigate } from "../../routes";

const useSwitchStyles = makeStyles((theme) => ({
  switchButtonActive: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    boxShadow: "0 0 10px 0 rgba(0, 0, 0, .125)",
    borderRadius: "3px",
    cursor: "pointer",
  },
  switchButton: {
    backgroundColor: theme.palette.primary.main,
    border: `2px solid ${theme.palette.secondary.main}`,
    color: theme.palette.primary.contrastText,
    borderRadius: "3px",
    cursor: "pointer",
  },
}));

function OrderInformationStep({ orderForm, nextStep, prevStep }) {
  const theme = useTheme();
  const upperMD = useMediaQuery(theme.breakpoints.up("md"));
  const [
    { DESTINATION },
    {
      changeDeliveryTab,
      changeClientTab,
      changeCommentTab,
      changeDestination,
      saveOrder,
    },
  ] = useOrderForm();
  const [{ client }] = useAuth();
  const [isGenerating, setIsGenerating] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [openDestinationModal, setOpenDestinationModal] = useState(false);
  const [destination, setDestination] = useState(false);

  const switchClasses = useSwitchStyles();

  const valid = (_var) => !!_var && _var !== "";

  const isFormValid = (formData) => 
    formData.CLIFNOM && 
    formData.CLIFAD1 && 
    formData.CLIFVILLE && 
    formData.CLIFCP && 
    formData.CLIFTEL1;

  const isDeliveryTabValid = useCallback(() => {
    const { deliveryTab } = orderForm;
    return valid(deliveryTab.LIEULIVSOUHAIT); // &&
    //     valid(deliveryTab.ADLIV1) &&
    //     valid(deliveryTab.CPLIV) &&
    //     valid(deliveryTab.VILLIV) &&
    //     valid(deliveryTab.DTELIVSOUHAIT) && moment(deliveryTab.DTELIVSOUHAIT).isAfter(moment().subtract(1, 'day'))
  }, [orderForm]);

  const isClientTabValid = useCallback(() => {
    const { clientTab } = orderForm;
    return isFormValid(clientTab);
  }, [orderForm]);

  const generateOrder = () => {
    setIsGenerating(true);
    const commande = {
      COLLECTION: orderForm.collection,
      DESTINATION,
      DONNEUR_ORDRE: client.NOM_RESPONSABLE,
      CODCON: client.CODCON,
      product: {
        collection: orderForm.collection,
        name: orderForm.product,
        code: orderForm.model.code,
        variant: orderForm.model.variant,
        engine: orderForm.standardOptions.find((c) => c.code === "MO").option
          .SIFRA,
      },
      ...orderForm.deliveryTab,
      ...(DESTINATION === "C" ? orderForm.clientTab : {}),
      ...orderForm.commentTab,
      CONFIG_StandardOptions: qs.stringify(
        orderForm.standardOptions.reduce(
          (acc, item) => ({ ...acc, [item.code]: item.option.SIFRA }),
          {}
        )
      ),
      CONFIG_AdditionalOptions: qs.stringify(
        orderForm.additionalOptions.options.reduce(
          (acc, item) => ({
            ...acc,
            [item.code]: item.options
              .reduce((a, i) => [...a, i.SIFRA], [])
              .join(","),
          }),
          {}
        )
      ),
      CONFIG_Prix: qs.stringify({
        ...orderForm.standardOptions.reduce(
          (acc, item) =>
            (item.option.TARIFNETHT || 0) > 0 && {
              ...acc,
              [item.code + "¤" + item.option.SIFRA]:
                item.option.TARIFNETHT || 0,
            },
          {}
        ),
        ...orderForm.additionalOptions.options.reduce(
          (acc, item) => ({
            ...acc,
            ...item.options.reduce(
              (acc1, item1) =>
                (item1.TARIFNETHT || 0) > 0
                  ? {
                      ...acc1,
                      [item.code + "¤" + item1.SIFRA]: item1.TARIFNETHT || 0,
                    }
                  : acc1,
              {}
            ),
          }),
          {}
        ),
      }),
      TARIFOPTCDE:
        orderForm.standardOptions.reduce(
          (acc, item) => acc + (item.option.TARIFNETHT || 0),
          0
        ) +
        orderForm.additionalOptions.options.reduce(
          (acc, item) =>
            acc + item.options.reduce((a, i) => a + (i.TARIFNETHT || 0), 0),
          0
        ),
    };

    saveOrder(commande, (err, data) => {
      if (err) {
        return enqueueSnackbar(err.message, { variant: "error" });
      }
      setIsGenerating(false);
      navigate.replace("OrdersPage");
    });
  };

  const tabs = useMemo(
    () => [
      {
        isValid: isDeliveryTabValid(),
        component: (
          <DeliveryTab
            deliveryTab={orderForm.deliveryTab}
            changeDeliveryTab={changeDeliveryTab}
          />
        ),
        label: "Livraison",
      },
      ...(DESTINATION === "C"
        ? [
            {
              isValid: isClientTabValid(),
              component: (
                <ClientTab
                  clientTab={orderForm.clientTab}
                  changeClientTab={changeClientTab}
                />
              ),
              label: "Client",
            },
          ]
        : []),
      {
        isValid: true,
        component: (
          <CommentTab
            commentTab={orderForm.commentTab}
            changeCommentTab={changeCommentTab}
          />
        ),
        label: "Commentaire",
      },
    ],
    [
      changeClientTab,
      changeCommentTab,
      changeDeliveryTab,
      DESTINATION,
      isClientTabValid,
      isDeliveryTabValid,
      orderForm.clientTab,
      orderForm.commentTab,
      orderForm.deliveryTab,
    ]
  );

  const chooseDestination = () => {
    changeDestination(destination);
    setOpenDestinationModal(false);
  };

  useEffect(() => {
    if (!DESTINATION) {
      setOpenDestinationModal(true);
    }
  }, [DESTINATION]);

  return (
    <React.Fragment>
      <Box mt={-2}>
        <SubHeader>Séléctionner les informations de commande</SubHeader>
      </Box>
      <Box mt={2} mb={8}>
        <Container maxWidth="lg">
          <Grid container direction="row-reverse" spacing={4}>
            <Grid item xs>
              <Box
                position={upperMD ? "sticky" : "relative"}
                top={upperMD ? 65 : 0}
              >
                <Collapse in={upperMD}>
                  <ArticleRecap
                    image={orderForm.article.ADRIA_image}
                    article={orderForm.article}
                    selectedOptions={[
                      ...orderForm.standardOptions,
                      ...orderForm.additionalOptions.options,
                    ]}
                  />
                </Collapse>
              </Box>
            </Grid>
            <Grid item md={8} xs={12}>
              <Dialog open={openDestinationModal}>
                <DialogTitle>Cette commande est a destination de</DialogTitle>
                <Box mb={2} display="flex" justifyContent="center">
                  <Box
                    onClick={() => setDestination("C")}
                    className={
                      destination === "C"
                        ? switchClasses.switchButtonActive
                        : switchClasses.switchButton
                    }
                    mx={1}
                    p={2}
                    flex={1}
                    textAlign="center"
                  >
                    Client
                  </Box>
                  <Box
                    onClick={() => setDestination("S")}
                    className={
                      destination === "S"
                        ? switchClasses.switchButtonActive
                        : switchClasses.switchButton
                    }
                    mx={1}
                    p={2}
                    flex={1}
                    textAlign="center"
                  >
                    Stock
                  </Box>
                </Box>
                <Divider />
                <DialogActions>
                  <Button
                    disabled={!destination}
                    variant="contained"
                    color="secondary"
                    onClick={chooseDestination}
                  >
                    Enregistrer
                  </Button>
                </DialogActions>
              </Dialog>
              <OrderInformationTabs tabs={tabs} />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <FooterStep prevStep={prevStep}>
        <Button
          color="secondary"
          variant="contained"
          disabled={!tabs.every((t) => t.isValid) || isGenerating}
          endIcon={<ArrowForwardIcon />}
          onClick={generateOrder}
        >
          {isGenerating && (
            <CircularProgress
              color="secondary"
              size={14}
              style={{ marginRight: 10 }}
            />
          )}{" "}
          Générer la commande
        </Button>
      </FooterStep>
    </React.Fragment>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index, isValid) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    style: isValid
      ? undefined
      : {
          color: "orange",
        },
  };
}

const useTabsStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
  },
}));

export function OrderInformationTabs({ tabs }) {
  const classes = useTabsStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" elevation={0}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              label={
                <Box display="flex" justifyContent="center" alignItems="center">
                  {!tab.isValid && (
                    <WarningIcon
                      style={{ color: "orange", marginRight: "10px" }}
                    />
                  )}
                  {tab.label}
                </Box>
              }
              {...a11yProps(index, tab.isValid)}
            />
          ))}
        </Tabs>
      </AppBar>
      {tabs.map((tab, index) => (
        <TabPanel key={index} value={value} index={index}>
          {tab.component}
        </TabPanel>
      ))}
    </div>
  );
}

export default OrderInformationStep;
