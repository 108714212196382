import socanorLogo from "./images/socanor.png";
import socanorSupplierLogo from "./images/adria_logo.png";
import sunLivingSupplierLogo from "./images/sunliving_logo.png";
import districampLogo from "./images/districamp.png";
import districampSupplierLogo from "./images/elios_logo.png";

const DEV_MODE = process.env.NODE_ENV !== "production";

const societes = {
  SOCANOR: "cdes.socanor.fr",
  DISTRICAMP: "cdes.districamp.fr",
};

const DEV_HOST = societes.SOCANOR;

const HOST = DEV_MODE ? DEV_HOST : window.location.hostname;

const SocanorConfiguration = {
  URL_API: DEV_MODE
    ? "http://localhost/api"
    : `https://${societes.SOCANOR}/api`,
  MANIFEST: "/socanor/manifest.json",
  FAVICON: "/socanor/favicon.png",
  TITLE: "Socanor",
  THEME: "#fff",
  CODSOC: "ADRIA",
  LOGO: socanorLogo,
  SUPPLIER_LOGO: socanorSupplierLogo,
  SUPPLIER_2_LOGO: sunLivingSupplierLogo,
};

const DistricampConfiguration = {
  URL_API: DEV_MODE
    ? "http://localhost/api"
    : `https://${societes.DISTRICAMP}/api`,
  MANIFEST: "/districamp/manifest.json",
  FAVICON: "/districamp/favicon.png",
  TITLE: "Districamp",
  THEME: "#fff",
  CODSOC: "DISTRICAMP",
  LOGO: districampLogo,
  SUPPLIER_LOGO: districampSupplierLogo,
};

// Apply the choosen config
const applyConfigurations = (ConfigurationObject) => {
  const manifestEl = document.getElementById("manifest");
  const faviconEl = document.getElementById("favicon");
  const titleEl = document.getElementById("title");
  const themeEl = document.getElementById("theme");

  manifestEl.setAttribute("href", ConfigurationObject.MANIFEST);
  faviconEl.setAttribute("href", ConfigurationObject.FAVICON);
  titleEl.innerHTML = ConfigurationObject.TITLE;
  themeEl.setAttribute("content", ConfigurationObject.THEME);
};

const getConfiguration = () => {
  switch (HOST) {
    case societes.SOCANOR:
      return SocanorConfiguration;
    case societes.DISTRICAMP:
      return DistricampConfiguration;
  }
};

const configuration = getConfiguration();

applyConfigurations(configuration);

export default configuration;
