import atob from 'atob';
import moment from 'moment';

export function isMobile() {
    if( navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
    ){
       return true;
     }
    else {
       return false;
     }
   }

export function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
  else
      byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], {type:mimeString});
}


export function access(obj, properties) {
  if (!properties) return obj;

  const props = properties.split('.');

  if (props.length > 1) {
    return access(obj[props[0]], props.splice(1).join('.'));
  } else {
    return obj[props[0]];
  }
}

export function price(num) {
  num = formatedPrice(num);
  var parts = num.toString().split(",");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(",");
}

export function formatedPrice(price) {
  price = price.toString().replace('.', ',');
  var hasComma = price.toString().indexOf(',');
  if (hasComma > -1) {
    /* there are som decimal */
    var arr = price.split(',');
    if (arr[1].length < 2) {
      price += "0";
    }
  }else {
    price += ",00";
  }

  return price
}

export function frDate(dateStr) {
  return moment(dateStr).format('DD/MM/YYYY');
}

export function formatedText(text) {
  return text.replace(/(?:\r\n|\r|\n)/g, '<br />');
}