import React from "react";
import { Box, Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  row: {
    marginBottom: theme.spacing(2),
  },
}));

function ClientTab({ clientTab, changeClientTab }) {
  const classes = useStyles();

  const handleChange = (e) => {
    changeClientTab(e.target.name, e.target.value);
  };

  return (
    <div>
      <Grid className={classes.row} container spacing={2}>
        <Grid item sm={4} xs={12}>
          <TextField
            variant="outlined"
            label="Nom du client final"
            required
            fullWidth
            color="secondary"
            value={clientTab.CLIFNOM}
            name={"CLIFNOM"}
            onChange={handleChange}
          />
        </Grid>
        <Grid item sm={8} xs={12}>
          <TextField
            variant="outlined"
            label="Adresse (1)"
            required
            fullWidth
            color="secondary"
            value={clientTab.CLIFAD1}
            name={"CLIFAD1"}
            onChange={handleChange}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <TextField
            variant="outlined"
            label="Adresse (2)"
            fullWidth
            color="secondary"
            value={clientTab.CLIFAD2}
            name={"CLIFAD2"}
            onChange={handleChange}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextField
            variant="outlined"
            label="Code postal"
            required
            fullWidth
            color="secondary"
            value={clientTab.CLIFCP}
            name={"CLIFCP"}
            onChange={handleChange}
          />
        </Grid>
        <Grid item sm={8} xs={12}>
          <TextField
            variant="outlined"
            label="Ville"
            required
            fullWidth
            color="secondary"
            value={clientTab.CLIFVILLE}
            name={"CLIFVILLE"}
            onChange={handleChange}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextField
            variant="outlined"
            label="Téléphone (1)"
            required
            fullWidth
            color="secondary"
            value={clientTab.CLIFTEL1}
            name={"CLIFTEL1"}
            onChange={handleChange}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextField
            variant="outlined"
            label="Téléphone (2)"
            fullWidth
            color="secondary"
            value={clientTab.CLIFTEL2}
            name={"CLIFTEL2"}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Box mt={2}>
        <Typography variant="caption" component="p">
          (*) Les champs munis d'un astérisque sont obligatoires
        </Typography>
      </Box>
    </div>
  );
}

export default ClientTab;
