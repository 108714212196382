import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Button,
  IconButton,
  Tooltip,
  Dialog,
  AppBar,
  Toolbar,
  TextField,
  Grid,
  Divider,
  CircularProgress,
  Collapse,
  Checkbox,
} from "@material-ui/core";
import { access, isMobile, formatedText } from "../../utils";
import moment from "moment";
import useFilters from "../Filters/useFilters";
import {
  Visibility as VisibilityIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  HourglassEmpty as HourglassEmptyIcon,
  AccountCircle as AccountCircleIcon,
  ShowChart as ShowChartIcon,
  Edit as EditIcon,
} from "@material-ui/icons";
import { navigate } from "../../routes";
import { useSnackbar } from "notistack";
import { toFR } from "../../config/periods.config";
import Loader from "../Loader";

const columns = [
  {
    label: "Collection",
    code: "COLLECTION",
    props: {
      align: "center",
      style: {
        backgroundColor: "#6D6D6D",
        color: "white",
        fontWeight: "bold",
      },
    },
  },
  {
    label: "N° de commande",
    format: (v) =>
      v.STATUT !== "W" ? v.NUMCDE : (
        `<span style="opacity: 0.4;">${v.NUMCDE}</span>`
      ),
    // code: 'NUMCDE',
    props: {
      style: {
        fontWeight: "bold",
        maxWidth: 75,
      },
    },
  },
  {
    label: "Date",
    code: "DTECDE",
    format: (v) => v && moment(v).format("DD/MM/YYYY"),
  },
  {
    label: "Statut",
    code: "STATUT",
    format: (v) => (v === "W" ? "En attente" : "Commande"),
  },
  {
    label: "Article",
    code: "article",
    format: (v) =>
      (v.LIBARTCON || v.ADRIA_name || "") +
      " " +
      (v.MO_LIBARTCON || v.ADRIA_MO_naziv || ""),
    props: {
      style: {
        minWidth: "200px",
      },
    },
  },
  {
    label: 'Options',
    code: 'LISTE_OPTIONS_PLUS',
    format: (v) => v && formatedText(v),
  },
  {
    label: "Livraison souhaitée",
    code: "DTELIVSOUHAIT",
    format: (v) => v && v.cleanDate() && moment(v).format("DD/MM/YYYY"),
  },
  {
    label: "Client final",
    code: "CLIFNOM",
    props: {
      style: {
        minWidth: "150px",
      },
    },
  },
  {
    label: "N° de chassis",
    code: "NUMCHASSIS",
  },
  {
    label: "Livrée",
    code: "DTEEXPEDITION",
    format: (v) => v && v.cleanDate() ? 'Oui' : 'Non',
  },
  // {
  //   label: "Affectation",
  //   code: "DTELIVAFFECTE",
  //   format: (v) =>
  //     v &&
  //     v.cleanDate() &&
  //     toFR(moment(v).format("MMM")) + ". " + moment(v).format("YYYY"),
  // },
  {
    label: "Production",
    code: "IMPACT_SUPPLY_PLAN",
    format: (v) => v,
  },
  {
    label: "PV",
  },
];

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    marginTop: theme.spacing(2),
    maxHeight: "100%",
    height: "100%",
  },
  headerStyle: {
    color: "#E2001A",
  },
  table: {
    fontSize: 10,
  },
  cell: {
    padding: "6px",
  },
}));

function OrdersList({
  orders = [],
  putOrder,
  deleteOrder,
  fetching,
  onChangingCollection,
}) {
  const classes = useStyles();

  const [filters] = useFilters();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const periodFilter = (order) => {
    if (filters.period === "all") return true;

    if (filters.period === "none" && !order.DTELIVAFFECTE) return true;

    return filters.period === order.DTELIVAFFECTE;
  };

  const getPVMines = (order) => {
    const { PVMINES, PVMINES_DATE, STATUT } = order;
    const pv = PVMINES;
    const canAskForPV = STATUT === "O" || STATUT === "S";

    if (!canAskForPV) return null;

    if (pv === "D" && PVMINES_DATE > "1900-01-01") {
      return (
        <Tooltip title={`Le ${moment(PVMINES_DATE).format("DD/MM/YYYY")}`}>
          <Button
            size="small"
            fullWidth
            onClick={() => setSelectedOrder(order)}
            color="secondary"
            variant="outlined"
          >
            Modifier
          </Button>
        </Tooltip>
      );
    } else if (pv === "D") {
      return (
        <Button
          size="small"
          fullWidth
          onClick={() => setSelectedOrder(order)}
          color="secondary"
          variant="outlined"
        >
          Modifier
        </Button>
      );
    } else if (!pv || pv === "") {
      return (
        <Button
          size="small"
          fullWidth
          onClick={() => setSelectedOrder(order)}
          color="secondary"
          variant="outlined"
        >
          Demander
        </Button>
      );
    } else if (pv === "A") {
      return null;
    }
  };

  const updateClient = (order) => {
    if (!(order.PVMINES === "A")) {
      setSelectedClient(order);
    } else {
      enqueueSnackbar("Erreur: La commande est déjà affectée.", {
        variant: "error",
      });
    }
  };

  const getWebCDEActions = (order) => {
    return (
      <React.Fragment>
        {order.NUMCDE && order.STATUT !== "O" && (
          <Tooltip
            title={
              order.STATUT === "W"
                ? "Voir la pré-commande"
                : "Voir la confirmation de commande"
            }
          >
            <IconButton
              size="small"
              onClick={() =>
                navigate.push("OrderPage", { numcde: String(order.NUMCDE) })
              }
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        )}
        {order.PVMINES !== "A" && (
          <Tooltip title="Modifier les informations client">
            <IconButton size="small" onClick={() => updateClient(order)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
        {order.CDE_EN_NEGO === "O" ? (
          <Tooltip title="Commande en cours de validation">
            <IconButton size="small">
              <HourglassEmptyIcon />
            </IconButton>
          </Tooltip>
        ) : (
          order.STATUT === "W" && (
            <Tooltip title="Supprimer">
              <IconButton
                size="small"
                onClick={() =>
                  window.confirm(
                    "La suppression est définitive. Souhaitez-vous poursuivre?"
                  ) && deleteOrder(order.NUMCDE)
                }
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )
        )}
        {order.DESTINATION === "C" && (
          <Tooltip title="Client">
            <IconButton size="small">
              <AccountCircleIcon />
            </IconButton>
          </Tooltip>
        )}
        {order.DESTINATION === "S" && (
          <Tooltip title="Stock">
            <IconButton size="small">
              <ShowChartIcon />
            </IconButton>
          </Tooltip>
        )}
      </React.Fragment>
    );
  };

  return (
    <Box my={2}>
      <Typography variant="subtitle2" component="p">
        {orders.length > 0 &&
          `${orders.length} commandes`}
      </Typography>
      <TableContainer className={classes.container}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {columns.map((column) => (
                <TableCell
                  key={column.label}
                  className={classes.headerStyle}
                  style={{ minWidth: "100px" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={15} style={{ padding: 0 }} align="center">
                <Collapse in={fetching}>
                  <Box>
                    <Loader spacing={1} size={16} />
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
            {!onChangingCollection &&
              orders.map((order) => (
                <TableRow
                  key={order.NUMCDE}
                  style={{
                    backgroundColor:
                      order.CDE_EN_NEGO === "O"
                        ? "rgba(189, 65, 71, .15)"
                        : "none",
                  }}
                >
                  <TableCell
                    className={classes.cell}
                    style={{ fontSize: 10, padding: "6px" }}
                  >
                    <Box display="flex">{getWebCDEActions(order)}</Box>
                  </TableCell>
                  {columns
                    .filter((c) => c.code || c.format)
                    .map((column) => (
                      <TableCell
                        className={classes.cell}
                        {...(column.props || {})}
                        key={column.code}
                        dangerouslySetInnerHTML={{ __html: column.format
                          ? column.format(access(order, column.code))
                          : access(order, column.code)}}
                      />
                    ))}
                  <TableCell className={classes.cell} align="center">
                    {getPVMines(order)}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PVMinesDialog
        open={!!selectedOrder}
        onClose={() => setSelectedOrder(null)}
        selectedOrder={selectedOrder}
        putOrder={putOrder}
      />
      <UpdateClientDialog
        open={!!selectedClient}
        onClose={() => setSelectedClient(null)}
        selectedOrder={selectedClient}
        putOrder={putOrder}
      />
    </Box>
  );
}

const usePVMinesStyles = makeStyles((theme) => ({
  head: {
    backgroundColor: "#eee",
  },
}));

const PVMinesDialog = ({ selectedOrder, putOrder, ...props }) => {
  const classes = usePVMinesStyles();
  const today = moment(
    (selectedOrder?.PVMINES_DATE && selectedOrder?.PVMINES_DATE.cleanDate()) ||
      undefined
  ).format("YYYY-MM-DD");
  const [pv, setPv] = useState({ PVMINES_DATE: today, ...selectedOrder });
  const [puting, isPuting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setPv({ PVMINES_DATE: today, ...selectedOrder })
  }, [selectedOrder, today])

  if (!selectedOrder) return null;

  const handleChange = (e) => {
    if (e.target.name === 'DTEVENTE') {
      setPv({ ...(pv || {}), [e.target.name]: e.target.value, salesDateUpdated: true });
    } else {
      setPv({ ...(pv || {}), [e.target.name]: e.target.value });
    }
  }


  const isFormValid = (formData) => 
    formData.CLIFNOM && 
    formData.CLIFAD1 && 
    formData.CLIFVILLE && 
    formData.CLIFCP && 
    formData.CLIFTEL1;
    
  const put = () => {
    if (!isFormValid(pv)) {
      enqueueSnackbar("Erreur: Certains champs requis sont manquants.", {
        variant: "error",
      });
      return;
    }

    const comparativeDate = selectedOrder.DTEVENTE.cleanDate() !== '' ? moment(selectedOrder.DTEVENTE) : moment().format('YYYY-MM-DD');
    const inputDate = pv.DTEVENTE !== '' ? pv.DTEVENTE : '1900-01-01';
    
    if (pv.DTEVENTE !== selectedOrder.DTEVENTE && moment(inputDate).isBefore(comparativeDate)) {
      enqueueSnackbar("Erreur: La date de vente doit être supérieure ou égale à la date du jour / la date de vente antérieurement saisie.", {
        variant: "error",
      });
      return;
    }

    if (!moment(pv.PVMINES_DATE).isValid()) {
      return enqueueSnackbar("Erreur: La date saisie n'est pas valide", {
        variant: "error",
      });
    }

    isPuting(true);
    putOrder(selectedOrder.NUMCDE, { ...pv, PVMINES: "D" }, (err, data) => {
      isPuting(false);
      if (err)
        return enqueueSnackbar("Erreur: Réessayez plus tard.", {
          variant: "error",
        });
      setPv(null);
      props.onClose();
    });
  };

  return (
    <Dialog {...props} fullWidth fullScreen={isMobile()}>
      <Box>
        <AppBar position="relative">
          <Toolbar>
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <div>
                <Typography variant="h6">{selectedOrder.NUMCDE}</Typography>
                <Typography variant="subtitle2">
                  {moment(selectedOrder.DTECDE).format("DD/MM/YYYY")}
                </Typography>
              </div>
              <Box display="flex">
                <IconButton onClick={props.onClose} size="small">
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        <Box p={3}>
          <Box mb={3}>
            <Typography gutterBottom variant="h5" component="p">
              Demande de PV
            </Typography>
            <Typography variant="h5" component="p">
              {(selectedOrder.article.LIBARTCON ||
                selectedOrder.article.ADRIA_name ||
                "") +
                " " +
                (selectedOrder.article.MO_LIBARTCON ||
                  selectedOrder.article.ADRIA_MO_naziv ||
                  "")}
            </Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item sm={5} xs={12} className={classes.head}>
              <TextField
                variant="outlined"
                label="Date"
                type="date"
                inputProps={{
                  min: moment().format("YYYY-MM-DD"),
                  defaultValue: today,
                }}
                fullWidth
                color="secondary"
                name={"PVMINES_DATE"}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={7} xs={12} className={classes.head}>
              <TextField
                variant="outlined"
                label="N° de facture"
                fullWidth
                color="secondary"
                name={"PV_MINES_NUMFAC"}
                defaultValue={
                  selectedOrder.PV_MINES_NUMFAC > 0
                    ? selectedOrder.PV_MINES_NUMFAC
                    : ""
                }
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="Date de vente"
                fullWidth
                type='date'
                color="secondary"
                defaultValue={selectedOrder.DTEVENTE && selectedOrder.DTEVENTE.cleanDate()}
                name="DTEVENTE"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                variant="outlined"
                label="Nom du client final"
                required
                fullWidth
                color="secondary"
                defaultValue={selectedOrder.CLIFNOM}
                name={"CLIFNOM"}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={8} xs={12}>
              <TextField
                variant="outlined"
                label="Adresse (1)"
                required
                fullWidth
                color="secondary"
                defaultValue={selectedOrder.CLIFAD1}
                name={"CLIFAD1"}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <TextField
                variant="outlined"
                label="Adresse (2)"
                fullWidth
                color="secondary"
                defaultValue={selectedOrder.CLIFAD2}
                name={"CLIFAD2"}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                variant="outlined"
                label="Code postal"
                required
                fullWidth
                color="secondary"
                defaultValue={selectedOrder.CLIFCP}
                name={"CLIFCP"}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={8} xs={12}>
              <TextField
                variant="outlined"
                label="Ville"
                required
                fullWidth
                color="secondary"
                defaultValue={selectedOrder.CLIFVILLE}
                name={"CLIFVILLE"}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                variant="outlined"
                label="Téléphone (1)"
                required
                fullWidth
                color="secondary"
                defaultValue={selectedOrder.CLIFTEL1}
                name={"CLIFTEL1"}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                variant="outlined"
                label="Téléphone (2)"
                fullWidth
                color="secondary"
                defaultValue={selectedOrder.CLIFTEL2}
                name={"CLIFTEL2"}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Box mt={2}>
            <Typography variant="caption" component="p">
              (*) Les champs munis d'un astérisque sont obligatoires
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box py={2} px={3} display="flex" justifyContent="flex-end">
        <Button
          disabled={puting || !pv}
          variant="contained"
          color="secondary"
          onClick={put}
        >
          {puting && (
            <CircularProgress
              style={{ marginRight: 10 }}
              size={12}
              color="secondary"
            />
          )}
          Enregistrer
        </Button>
      </Box>
    </Dialog>
  );
};

const UpdateClientDialog = ({ selectedOrder, putOrder, ...props }) => {
  const [pv, setPv] = useState(selectedOrder);
  const [puting, isPuting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  
  useEffect(() => {
    setPv(selectedOrder)
  }, [selectedOrder])
  
  if (!selectedOrder) return null;

  const handleChange = (e) => {
    if (e.target.name === 'DTEVENTE') {
      setPv({ ...(pv || {}), [e.target.name]: e.target.value, salesDateUpdated: true });
    } else {
      setPv({ ...(pv || {}), [e.target.name]: e.target.value });
    }
  }

  const isFormValid = (formData) => 
    formData.CLIFNOM && 
    formData.CLIFAD1 && 
    formData.CLIFVILLE && 
    formData.CLIFCP && 
    formData.CLIFTEL1;

  const put = () => {
    const comparativeDate = selectedOrder.DTEVENTE.cleanDate() !== '' ? moment(selectedOrder.DTEVENTE) : moment().format('YYYY-MM-DD');
    const inputDate = pv.DTEVENTE !== '' ? pv.DTEVENTE : '1900-01-01';
    
    if (pv.DTEVENTE !== selectedOrder.DTEVENTE && moment(inputDate).isBefore(comparativeDate)) {
      enqueueSnackbar("Erreur: La date de vente doit être supérieure ou égale à la date du jour / la date de vente antérieurement saisie.", {
        variant: "error",
      });
      return;
    }
    
    if (!isFormValid(pv)) {
      enqueueSnackbar("Erreur: Certains champs requis sont manquants.", {
        variant: "error",
      });
      return;
    }

    isPuting(true);
    putOrder(selectedOrder.NUMCDE, { ...pv }, (err, data) => {
      isPuting(false);
      if (err)
        return enqueueSnackbar("Erreur: Réessayez plus tard.", {
          variant: "error",
        });
      setPv(null);
      props.onClose();
    });
  };

  return (
    <Dialog {...props} fullWidth fullScreen={isMobile()}>
      <Box>
        <AppBar position="relative">
          <Toolbar>
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <div>
                <Typography variant="h6">{selectedOrder.NUMCDE}</Typography>
                <Typography variant="subtitle2">
                  {moment(selectedOrder.DTECDE).format("DD/MM/YYYY")}
                </Typography>
              </div>
              <Box display="flex">
                <IconButton onClick={props.onClose} size="small">
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        <Box p={3}>
          <Box mb={3}>
            <Typography gutterBottom variant="h5" component="p">
                Informations Client
              </Typography>
            <Typography variant="h5" component="p">
              {(selectedOrder.article.LIBARTCON ||
                selectedOrder.article.ADRIA_name ||
                "") +
                " " +
                (selectedOrder.article.MO_LIBARTCON ||
                  selectedOrder.article.ADRIA_MO_naziv ||
                  "")}
            </Typography>
          </Box>
          <Grid container spacing={3}>
          <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="Date de vente"
                fullWidth
                type='date'
                color="secondary"
                defaultValue={selectedOrder.DTEVENTE && selectedOrder.DTEVENTE.cleanDate()}
                name="DTEVENTE"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                variant="outlined"
                label="Nom du client final"
                required
                fullWidth
                color="secondary"
                defaultValue={selectedOrder.CLIFNOM}
                name={"CLIFNOM"}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={8} xs={12}>
              <TextField
                variant="outlined"
                label="Adresse (1)"
                required
                fullWidth
                color="secondary"
                defaultValue={selectedOrder.CLIFAD1}
                name={"CLIFAD1"}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <TextField
                variant="outlined"
                label="Adresse (2)"
                fullWidth
                color="secondary"
                defaultValue={selectedOrder.CLIFAD2}
                name={"CLIFAD2"}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                variant="outlined"
                label="Code postal"
                required
                fullWidth
                color="secondary"
                defaultValue={selectedOrder.CLIFCP}
                name={"CLIFCP"}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={8} xs={12}>
              <TextField
                variant="outlined"
                label="Ville"
                required
                fullWidth
                color="secondary"
                defaultValue={selectedOrder.CLIFVILLE}
                name={"CLIFVILLE"}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                variant="outlined"
                label="Téléphone (1)"
                required
                fullWidth
                color="secondary"
                defaultValue={selectedOrder.CLIFTEL1}
                name={"CLIFTEL1"}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                variant="outlined"
                label="Téléphone (2)"
                fullWidth
                color="secondary"
                defaultValue={selectedOrder.CLIFTEL2}
                name={"CLIFTEL2"}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="Commentaire"
                multiline
                rows={3}
                fullWidth
                color="secondary"
                defaultValue={selectedOrder.COMMENTAIRE}
                name={"COMMENTAIRE"}
                onChange={handleChange}
              />
              </Grid>
          </Grid>
          <Box mt={2}>
            <Typography variant="caption" component="p">
              (*) Les champs munis d'un astérisque sont obligatoires
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box py={2} px={3} display="flex" justifyContent="flex-end">
        <Button
          disabled={puting || !pv}
          variant="contained"
          color="secondary"
          onClick={put}
        >
          {puting && (
            <CircularProgress
              style={{ marginRight: 10 }}
              size={12}
              color="secondary"
            />
          )}
          Enregistrer
        </Button>
      </Box>
    </Dialog>
  );
};

export default OrdersList;
