import React from 'react'
import { FormControl, InputLabel, Input, FormHelperText, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    row: {
        marginBottom: theme.spacing(2),
    }
}));

function CommentTab({ commentTab, changeCommentTab }) {

    const classes = useStyles();
    
    const handleChange = e => {
        changeCommentTab(e.target.name, e.target.value);
    }
    
    return (
        <div>
            <Grid className={classes.row} container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        variant='outlined'
                        label='Commentaire'
                        fullWidth
                        color='secondary'
                        multiline
                        value={commentTab.COMMENTAIRE}
                        name={'COMMENTAIRE'}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default CommentTab;
