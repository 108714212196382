import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { initializeStore } from './store';
import customTheme from './theme';
import './helpers';
import { SnackbarProvider } from 'notistack';

const store = initializeStore();

const theme = createMuiTheme(customTheme);

ReactDOM.render(
  <React.StrictMode>

    <Provider store={store}>

      <SnackbarProvider maxSnack={1}>
      
        {/* Provider material ui theming */}
        <ThemeProvider theme={theme}>

          {/* The entire application */}
          <App />
          
        </ThemeProvider>
        
      </SnackbarProvider>    

    </Provider>
    
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
