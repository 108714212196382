import * as actions from "./actions";
import moment from "moment";

const initialState = {
  article: null,
  collection: null,
  product: null,
  model: null,
  standardOptions: null,
  additionalOptions: {
    options: null,
    added: [],
    removed: [],
  },
  deliveryTab: {},
  clientTab: {},
  commentTab: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.SET_ARTICLE:
      return {
        ...state,
        //
        article: action.payload,
      };
      break;

    case actions.SELECT_COLLECTION:
      return {
        ...initialState,
        //
        collection: action.payload,
      };
      break;

    case actions.SELECT_PRODUCT:
      return {
        ...initialState,
        collection: state.collection,
        //
        product: action.payload,
      };
      break;

    case actions.SELECT_MODEL:
      return {
        ...initialState,
        collection: state.collection,
        product: state.product,
        //
        model: action.payload,
      };
      break;

    case actions.SELECT_STANDARD_OPTIONS:
      return {
        ...initialState,
        collection: state.collection,
        product: state.product,
        model: state.model,
        article: state.article,
        //
        standardOptions: action.payload,
      };
      break;

    case actions.SELECT_STANDARD_OPTION:
      return {
        ...initialState,
        collection: state.collection,
        product: state.product,
        model: state.model,
        article: state.article,
        //
        standardOptions: state.standardOptions.map((categ) =>
          categ.code === action.payload.KATEG
            ? { ...categ, option: action.payload }
            : categ
        ),
      };
      break;
    case actions.SELECT_ADDITIONAL_OPTIONS:
      return {
        ...initialState,
        collection: state.collection,
        product: state.product,
        model: state.model,
        standardOptions: state.standardOptions,
        article: state.article,
        //
        additionalOptions: {
          options: action.payload,
          added: [],
          removed: [],
        },
      };
      break;

    case actions.SELECT_ADDITIONAL_OPTION: {
      const { option, options } = action.payload;
      // let newAdditionalOptions = state.additionalOptions.map(categ => categ.code === option.KATEG ? (({...categ, options: !!(categ.options.find(opt => opt.SIFRA === option.SIFRA)) ? categ.options.filter(opt => opt.SIFRA !== option.SIFRA) : [...categ.options, option]})) : categ).filter(opt => opt !== null);

      let addedOptions = [];
      let removedOptions = [];
      let defaultOptions = [];

      const allAddOptions = options.reduce(
        (acc, item) => [...acc, ...item.options],
        []
      );
      const clickedOption = allAddOptions.find((o) => o.SIFRA === option.SIFRA);
      const flatConnections = state.standardOptions
        .reduce((acc, item) => [...acc, item.option], [])
        .reduce((acc, item) => [...acc, ...item.CONNECTIONS], []);
      let currentOptions = state.additionalOptions.options
        .reduce((acc, item) => [...acc, ...item.options], [])
        .filter((o) => o.removed !== true);

      // Does the option must be added or remove ?
      if (!currentOptions.find((o) => o.SIFRA === clickedOption.SIFRA)) {
        // Must be added
        addedOptions.push({ ...clickedOption, fresh: true });
        // Run connections
        clickedOption.CONNECTIONS.forEach((co) => {
          const relativeOption = allAddOptions.find(
            (ao) => ao.SIFRA === co.OPPO_OPCIJ
          );
          if (relativeOption) {
            if (
              co.OPPO_VRSTA === "A" &&
              !currentOptions.find((o) => o.SIFRA === relativeOption.SIFRA)
            )
              addedOptions.push({ ...relativeOption, fresh: true });
            if (
              co.OPPO_VRSTA === "D" &&
              !!currentOptions.find((o) => o.SIFRA === relativeOption.SIFRA)
            )
              removedOptions.push(relativeOption);
          }
        });
      } else {
        //
        removedOptions.push(clickedOption);
        allAddOptions.map((AO) => {
          const connection = flatConnections.find(
            (co) => co.OPPO_OPCIJ === AO.SIFRA && co.OPPO_VRSTA === "A"
          );
          if (connection || AO.OBVEZNO === "D")
            defaultOptions.push({ ...AO, OBVEZNO: "O", default: true });
        });
        clickedOption.CONNECTIONS.forEach((co) => {
          defaultOptions.forEach((o) => {
            if (o.SIFRA === co.OPPO_OPCIJ && co.OPPO_VRSTA === "D") {
              // There is a connection on removed option that has uncheck a default
              // We have te recheck the default
              addedOptions.push({ ...o, default: true });
            }
          });
        });
      }

      // New Current options
      currentOptions = [...currentOptions, ...addedOptions].filter(
        (o1) => !removedOptions.find((o2) => o1.SIFRA === o2.SIFRA)
      );

      const getByCateg = (options) => {
        return options.reduce((acc, o) => {
          const index = acc.findIndex((it) => it.code === o.KATEG);
          if (index >= 0) {
            // We already have this KATEG
            acc[index].options.push(o);
          } else {
            acc.push({
              code: o.KATEG,
              name: o.KATE_NAZIV,
              options: [o],
            });
          }
          return acc;
        }, []);
      };
      return {
        ...initialState,
        collection: state.collection,
        product: state.product,
        model: state.model,
        standardOptions: state.standardOptions,
        article: state.article,
        //
        additionalOptions: {
          options: getByCateg(currentOptions),
          added: addedOptions,
          removed: removedOptions,
        },
      };
      break;
    }

    case actions.PREFILL_ORDER_INFORMATION: {
      const client = action.payload;

      return {
        ...state,
        deliveryTab: {
          LIEULIVSOUHAIT: client.NOMCON,
          ADLIV1: client.AD1,
          ADLIV2: client.AD2,
          CPLIV: client.CODPOS,
          VILLIV: client.VILLE,
          DTELIVSOUHAIT: moment().format("YYYY-MM-DD"),
        },
      };

      break;
    }

    case actions.CHANGE_DELIVERY_TAB: {
      const { key, value } = action.payload;
      return {
        ...state,
        deliveryTab: {
          ...state.deliveryTab,
          [key]: value,
        },
      };
      break;
    }

    case actions.CHANGE_CLIENT_TAB: {
      const { key, value } = action.payload;
      return {
        ...state,
        clientTab: {
          ...state.clientTab,
          [key]: value,
        },
      };
      break;
    }

    case actions.CHANGE_COMMENT_TAB: {
      const { key, value } = action.payload;
      return {
        ...state,
        commentTab: {
          ...state.commentTab,
          [key]: value,
        },
      };
      break;
    }

    case actions.CHANGE_DESTINATION:
      return {
        ...state,
        DESTINATION: action.payload,
      };

    default:
      return state;
      break;
  }
}
