import confs from '../../confs';
import { request } from '../../request';

const { URL_API } = confs;

export default {

    // Get orders
    cGet: (params) => request.get(`${URL_API}/me/orders`, params),
    put: (id, data) => request.post(`${URL_API}/orders/${id}`, data),
    delete: (id) => request.get(`${URL_API}/orders/${id}/delete`),
    
};