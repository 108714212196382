import React from "react";
import {
  AppBar,
  Box,
  makeStyles,
  Button,
  Tab,
  Tabs,
  Menu,
  MenuItem,
  Typography,
  Toolbar,
} from "@material-ui/core";
import { ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";
import { useState } from "react";
import { navigate } from "../routes";
import useAuth from "./Auth/useAuth";
import confs from "../confs";

const { LOGO, SUPPLIER_LOGO, SUPPLIER_2_LOGO } = confs;

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 15,
    width: "auto",
    marginRight: "16px",
  },
}));

function Header({ user, menu }) {
  const classes = useStyles();
  const [_, { logout }] = useAuth();
  const [m, setMenu] = useState(menu);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <AppBar color="primary" variant="elevation" position="fixed">
        <Box
          mx={3}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {/* Left pane */}
          <Box display="flex" alignItems="center">
            <img alt="Logo" src={LOGO} className={classes.logo} />
            <img
              alt="Supplier Logo"
              src={user.code_societe === 'ADRIA' ? SUPPLIER_LOGO : SUPPLIER_2_LOGO}
              className={classes.logo}
            />
            <Box mx={2}>
              <Tabs
                value={m}
                onChange={(e, v) => setMenu(v)}
                textColor="secondary"
                aria-label="simple tabs example"
              >
                <Tab
                  onClick={() => navigate.push("OrdersPage")}
                  label="Mes commandes"
                />
                <Tab
                  onClick={() =>
                    navigate.push("CreateOrderPage", {}, { hash: "collection" })
                  }
                  label="Passer une commande"
                />
              </Tabs>
            </Box>
          </Box>

          {/* Right pane */}
          <Box>
            {user && (
              <React.Fragment>
                <Button
                  aria-controls="user-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  endIcon={<ArrowDropDownIcon size={6} />}
                >
                  {user.name}
                </Button>
                <Menu
                  id="user-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <Box p={2}>
                    <Typography variant="subtitle2">Donneur d'ordre</Typography>
                    <Typography>{user.manager}</Typography>
                  </Box>
                  <MenuItem onClick={logout}>Déconnexion</MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </Box>
        </Box>
      </AppBar>
      <Toolbar />
    </React.Fragment>
  );
}

export default Header;
