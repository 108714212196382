import React, { useState, useEffect } from 'react';
import { Box, Grid, Container, Collapse, Typography, TableContainer, Table, TableHead, TableCell, TableRow, TableBody, Button, Popover, MenuItem, Menu, Divider, IconButton, Chip } from '@material-ui/core'
import SubHeader from '../SubHeader'
import confs from '../../confs';
import { request } from '../../request';
import { useSnackbar } from 'notistack';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Loader from '../Loader';
import { makeStyles } from '@material-ui/styles';
import FooterStep from './FooterStep';
import { ArrowForward as ArrowForwardIcon, Check, Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@material-ui/icons';
import { ArticleRecap } from './StandardOptionsStep';
import qs from 'querystring';
import useAuth from '../Auth/useAuth';
import { price } from '../../utils';
import { useMemo } from 'react';

const { URL_API } = confs;

const useStyles = makeStyles({
    nextButton: {
        backgroundColor: '#E2001A',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#f5384e',
        }
    }
})

const getOptionsByCateg = (options) => {
    return options.reduce((acc, option) => {
        if (!(option.RENDERABLE === 'D')) return acc;

        const index = acc.findIndex(it => it.code === option.KATEG);
        if (index >= 0) { // We already have this KATEG
            acc[index].options.push(option);
        } else {
            acc.push({
                code: option.KATEG,
                name: option.KATE_NAZIV,
                options: [ option ],
            });
        }
        return acc;
    }, []);
}

function AdditionalOptionsStep({ orderForm, nextStep, selectOptions, selectOption, prevStep, setArticle, prefillOrderInformation }) {

    const [isFetching, setIsFetching] = useState(true);
    const [auth] = useAuth();
    const [options, setOptions] = useState([]);
    const [packs, setPacks] = useState([]);
    const [standardConfigOptions, setStandardConfigOptions] = useState([]);
    const [addOpts, setAddOpt] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    const theme = useTheme();
    const upperMD = useMediaQuery(theme.breakpoints.up('md'));

    const classes = useStyles();

    useEffect(() => {

        const fail = () => {
            enqueueSnackbar('Erreur au chargement de la donnée', { variant: 'error' });
            setIsFetching(false);
        }

        setIsFetching(true);
        request.post(`${URL_API}/options/additional`, {
            collection: orderForm.collection,
            product: orderForm.product,
            model: orderForm.model,
            options: orderForm.standardOptions.map(opt => opt.option),
            engine: (orderForm.standardOptions.find(opt => opt.code === 'MO').option||{}).SIFRA,
        })
            .then(res => {
                if (!res.success) return fail();

                setArticle(res.payload.article);
                const optionsByCateg = getOptionsByCateg(res.payload.options);
                setAddOpt(res.payload.options);
                let stdCfgOpts = getStandardConfigOptions(res.payload.options, res.payload.standardConfig);
                setStandardConfigOptions(stdCfgOpts);
                setOptions(optionsByCateg);
                setPacks(res.payload.pak_options);
                if (!(orderForm.additionalOptions.options) && !(stdCfgOpts.length > 0)) setDefaultAdditionalOptions(optionsByCateg, orderForm.standardOptions);
                setIsFetching(false);
                
            })
            .catch(err => console.log(err))

    }, []);

    useEffect(() => {

        if (addOpts && standardConfigOptions) {
            applyStandardConfig();
        }
        
    }, [ addOpts, standardConfigOptions ])

    const onSelectOption = (option, options) => {
        selectOption(option, options);
    }

    const getStandardConfigOptions = (options, standardConfig) => {
        return options.filter(o =>  standardConfig && standardConfig[o.KATEG] && String(standardConfig[o.KATEG]).split(',').includes(String(o.SIFRA)));
    }

    const setDefaultAdditionalOptions = (optionsByCateg, standardOptions) => {

        const flatStandardOptions = standardOptions.reduce((acc, item) => [...acc, item.option],[]);
        const flatConnections = flatStandardOptions.reduce((acc, item) => [...acc, ...item.CONNECTIONS], []);
        
        let defaultOptions = optionsByCateg.map(categ => {
            let options = [];

            categ.options.map(AO => {
                const connection = flatConnections.find(co => co.OPPO_OPCIJ === AO.SIFRA && co.OPPO_VRSTA === 'A');
                if (connection || AO.OBVEZNO === 'D') options.push({...AO, OBVEZNO: 'D', default: true });

            });
            
            return {...categ, options };
            
        });
        
        selectOptions(defaultOptions);
    };

    const applyStandardConfig = () => {

        const flatStandardOptions = orderForm.standardOptions.reduce((acc, item) => [...acc, item.option],[]);
        const flatConnections = flatStandardOptions.reduce((acc, item) => [...acc, ...item.CONNECTIONS], []);

        const defaultOptsFromParent = addOpts.filter(o => flatConnections.find(co => co.OPPO_OPCIJ === o.SIFRA && co.OPPO_VRSTA === 'A')).map(o => ({...o, default: true}));
        const configOpts = standardConfigOptions.map(o => (o.OBVEZNO === 'D' || !!flatConnections.find(co => co.OPPO_OPCIJ === o.SIFRA && co.OPPO_VRSTA === 'A')) ? {...o, default: true} : o);
        
        selectOptions(getOptionsByCateg([...configOpts, ...defaultOptsFromParent]));
    }

    const next = () => {
        prefillOrderInformation(auth.client);
        nextStep();
    }
    
    return (
        <React.Fragment>
            <Box mt={-2}>
                <SubHeader>
                    Séléctionner les options additionelles
                </SubHeader>
            </Box>
            <Box mt={2} mb={8}>

                {isFetching ? (
                    <Loader />
                ) : (
                    <Container maxWidth='lg'>
                        <Grid container direction='row-reverse' spacing={4}>
                            <Grid item xs>
                                <Box position={upperMD ? 'sticky' : 'relative'} top={upperMD ? 65 : 0}>
                                    <Collapse in={upperMD}>
                                        <ArticleRecap 
                                            article={orderForm.article}
                                            selectedOptions={[...orderForm.standardOptions, ...(orderForm.additionalOptions.options||[])]}
                                        />
                                    </Collapse>
                                </Box>
                            </Grid>
                            <Grid item md={8} xs={12}>
                                {standardConfigOptions.length > 0 && (
                                    <ApplyStandardConfigButton applyStandardConfig={applyStandardConfig} standardConfigOptions={standardConfigOptions}
                                    >
                                        Appliquer la configuration standard
                                    </ApplyStandardConfigButton>
                                )}
                                <Packs onSelectOption={onSelectOption} options={options} selectedOptions={orderForm.additionalOptions} packs={packs} />
                                <Options
                                    options={options}
                                    selectedOptions={(orderForm.additionalOptions)}
                                    onSelectOption={onSelectOption}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                )}

            </Box>
            <FooterStep prevStep={prevStep}>
                <Button 
                    disabled={!(!isFetching && !orderForm.standardOptions.find(it => it.option === undefined))}
                    color='secondary'
                    variant='contained'
                    endIcon={<ArrowForwardIcon />}
                    onClick={next}
                >
                    Suivant
                </Button>
            </FooterStep>
        </React.Fragment>
    );
}

const useOptionStyles = makeStyles({
    headerCell: {
        backgroundColor: '#FDFDFE',
        fontSize: 16
    },
    optionRow: {
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,.05)',
        },
        cursor: 'pointer',
    },
    optionRowSelected: {
        cursor: 'pointer',
        backgroundColor: 'rgba(0,0,0,.05)',
    }
});

const Options = ({ options, selectedOptions, onSelectOption }) => {

    const classes = useOptionStyles();
    
    return (
        <Box>
            <TableContainer>
                <Table size="small">
                    <TableBody>
                        {(options.map(categ => (
                            <React.Fragment>
                                <TableRow>
                                    <TableCell className={classes.headerCell} colSpan={4}>{categ.name}</TableCell>
                                </TableRow> 
                                {(categ.options.map(option => {
                                    const selectedOption = ((selectedOptions.options.find(c => c.code === categ.code)||{}).options || []).find(opt => opt.SIFRA === option.SIFRA);
                                    const isSelected = !!selectedOption && selectedOption.removed !== true;
                                    const isDefault = ((((selectedOptions.options.find(c => c.code === categ.code)||{}).options || []).find(opt => opt.SIFRA === option.SIFRA)||{}).default === true);

                                    const isAdded = selectedOptions.added.find(o1 => o1.SIFRA === option.SIFRA);
                                    const isRemoved = selectedOptions.removed.find(o1 => o1.SIFRA === option.SIFRA);

                                    return (
                                        <TableRow 
                                            className={isSelected ? classes.optionRowSelected : classes.optionRow} 
                                            style={isAdded ? ({backgroundColor: '#d4edda'}) : (isRemoved ? ({backgroundColor: '#f8d7da'}) : null)}
                                            onClick={() => !isDefault && onSelectOption(option, options) }
                                        >
                                            <TableCell>
                                                <input disabled={(isDefault ? 'true' : null)} type='checkbox' checked={isSelected} />
                                            </TableCell>
                                            <TableCell>{option.SIFRA}</TableCell>
                                            <TableCell>{(option.LIBOPTCON || option.NAZIV)}</TableCell>
                                            <TableCell>{option.TARIFNETHT > 0 && price((option.TARIFNETHT || 0))}</TableCell>
                                        </TableRow> 
                                    );
                                }))}
                            </React.Fragment>
                        )))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}


const useStandardConfigStyle = makeStyles(theme => ({
    root: {
        border: '1px solid rgba(0,0,0,.125)',
        borderRadius: '.3rem',
    },
    ul: {
        padding: 0,
        margin: 0,
        width: '100%',
        listStyle: 'none',
        fontSize: 12,
        marginBottom: '10px',
    },
    liHeader: {
        backgroundColor: '#dddfe2',
        color: '#464a4e',
        width: '100%',
        padding: theme.spacing(2),
    },
    li: {
        width: '100%',
        padding: theme.spacing(2),
    }
}));

function ApplyStandardConfigButton({ applyStandardConfig, standardConfigOptions }) {

    const [open, setOpen] = React.useState(false);
    const classes = useStandardConfigStyle();
    return (
      <div>
        <Box width='100%' display='flex' alignItems='center' justifyContent='center' mb={2}>
            <Button
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            // onMouseEnter={() => setOpen(true)}
            // onMouseLeave={() => setOpen(false)}
            color='secondary'
            fullWidth
            variant='contained'
            disableElevation
            onClick={applyStandardConfig}
            >
                Appliquer la configuration standard
            </Button>
            <Box ml={2}>
                <IconButton size='small' onClick={() => setOpen(!open)}>
                    {open ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
            </Box>
        </Box>
        <Collapse in={open}>
            <ul className={classes.ul}>
                {(getOptionsByCateg(standardConfigOptions).map(categ => {
                        return (
                            <React.Fragment>
                                <li className={classes.liHeader}>{categ.name}</li>
                                {categ.options.map(option => (
                                    <li className={classes.li}>
                                        <code>{option.SIFRA}</code> {option.NAZIV}
                                    </li>
                                ))}
                            </React.Fragment>
                        );
                }))}
                <Divider />
            </ul>
        </Collapse>
      </div>
    );
  }

  const Packs = ({ packs, onSelectOption, selectedOptions, options }) => {
      const isOptionSelected = (option) => {
        for (const categ of selectedOptions.options) {
            for (const selectedOption of categ.options) {
                if (selectedOption.SIFRA === option.SIFRA) {
                    return true;
                }
            }
        }
        return false;
      }
      
      const handleSelectPackOptions = (optionsCode, isPackSelected) => {
          for (const categ of options) {
              for (const option of categ.options) {
                  if (optionsCode.includes(option.SIFRA)) {
                      // Add the option
                      if (isPackSelected) {
                          // We want to UNSELECT this option
                          if (isOptionSelected(option)) onSelectOption(option, options);
                      }

                      if (!isPackSelected) {
                        // We want to select this option
                        if (!isOptionSelected(option)) onSelectOption(option, options);
                      }
                  }
              }
          }
      };

      const flattenOptions = selectedOptions.options.reduce((acc, item) => [...acc, ...item.options.reduce((acc, item) => [...acc, item],[])], [])
      const flattenOptionsCode = flattenOptions.map(({ SIFRA }) => SIFRA);
      
    return (
        <Box position="sticky" top={64} backgroundColor="white">
            <Box mb={2} display={'flex'} justifyContent='center' alignItems='center'>
                {packs.map(({ CODPAK, CONFIG_PakOptions }) => {
                    const packOptionsCode = CONFIG_PakOptions.split(',');
                    const isPackSelected = packOptionsCode.every(option => flattenOptionsCode.includes(option));
              
                    return <Box mr={0.5} mt={0.5}><Chip style={{ backgroundColor: 'white' }} icon={isPackSelected && <Check />} onClick={() => handleSelectPackOptions(packOptionsCode, isPackSelected)} label={CODPAK} variant='contained' /></Box>;
                })}
            </Box>
        </Box>
    );
  }

export default AdditionalOptionsStep;
