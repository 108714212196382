import React from 'react'
import { makeStyles, Box, Button, Container } from '@material-ui/core'
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#f8f9fa',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
    }, 
}));

function FooterStep({ prevStep, children }) {
    const classes = useStyles();
    return (
        <Box p={2} className={classes.root}>
            <Container maxWidth='lg'>
                <Box display='flex' justifyContent='space-between'>
                    <Button onClick={prevStep} startIcon={<ArrowBackIcon />}>
                        Précédent
                    </Button>
                    {children}
                </Box>
            </Container>
        </Box>
    );
}

export default FooterStep
