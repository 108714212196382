import * as actions from './actions';
import { useSelector, useDispatch } from 'react-redux';
import sdk from './sdk';

function useOrders() {

    const orders = useSelector(state => state.orders);
    const dispatch = useDispatch();
    
    const methods = {

        getMyOrders: (params, cb) => sdk.cGet(params).then(res => {
            if (res.success) {
                dispatch({ type: actions.CGET_ORDERS_SUCCESS, payload: res.payload });
                if (cb) cb(null, res.payload);
            } else {
                if (cb) cb(res, null);
            }
        }).catch(err => cb(err, null)),

        putOrder: (id, data, cb) => sdk.put(id, data).then(res => {
            if (res.success) {
                dispatch({ type: actions.PUT_ORDER_SUCCESS, payload: res.payload });
                if (cb) cb(null, res.payload);
            } else {
                if (cb) cb(res, null);
            }
        }).catch(err => cb(err, null)),

        deleteOrder: (id, cb) => sdk.delete(id).then(res => {
            if (res.success) {
                dispatch({ type: actions.DELETE_ORDER_SUCCESS, payload: res.payload });
                if (cb) cb(null, res.payload);
            } else {
                if (cb) cb(res, null);
            }
        }).catch(err => cb(err, null)),

    };

    return [orders, methods];
}

export default useOrders;