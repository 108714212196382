import React, { useEffect, useState, useRef, useMemo } from "react";
import { useParams } from "react-router";
import { request } from "../request";
import confs from "../confs";
import { useSnackbar } from "notistack";
import SubHeader from "../components/SubHeader";
import Header from "../components/Header";
import {
  Box,
  Container,
  Typography,
  Table,
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Chip,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Loader from "../components/Loader";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import qs from "querystring";
import {
  Print as PrintIcon,
  ArrowBack as ArrowBackIcon,
  HourglassEmpty as HourglassEmptyIcon,
} from "@material-ui/icons";
import useAuth from "../components/Auth/useAuth";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { formatedText, price } from "../utils";
import { navigate } from "../routes";
import ReactToPrint from "react-to-print";
import { toFR } from "../config/periods.config";
import rtfToHTML from "@iarna/rtf-to-html";

const { URL_API, LOGO, TITLE } = confs;

const RTFTemplate = (docs, defaults, content) =>
  `${content.replace(/\n/, "\n    ")}`;

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "#ccc",
    color: "#fff",
    fontWeight: "bold",
    borderRadius: theme.shape.borderRadius,
  },
  image: {
    height: 150,
    width: 150,
  },
}));

function OrderPage() {
  const classes = useStyles();
  const { numcde } = useParams();
  const [report, setReport] = useState({});
  const [{ user }] = useAuth();
  const [isFetching, setIsFetching] = useState(true);
  const [image, setImage] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setIsFetching(true);
    request
      .get(`${URL_API}/orders/${numcde}`)
      .then((data) => {
        if (!data.success) throw new Error("Erreur: " + data.status_msg);
        setReport(data.payload);
        setIsFetching(false);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
        navigate.push("NotFoundPage");
      });
  }, []);

  const { order, standardOptions, additionalOptions, packs, print } = report;
  const selectedStdOpts = order && qs.parse(order.CONFIG_StandardOptions);
  const selectedAddOpts = order && qs.parse(order.CONFIG_AdditionalOptions);

  const printOrder = () => {
    window.scrollTo({ top: 0 });
    const toPrint = document.getElementById("order-to-print");

    const A4Width = 585;
    const A4Height = 842;
    const A4Ratio = A4Width / A4Height;

    const cropWidth = toPrint.clientWidth;
    const cropHeight = toPrint.clientHeight;

    let nbPages = Math.ceil(((A4Width / cropWidth) * cropHeight) / A4Height);

    const pdf = new jsPDF("p", "px", [cropWidth, cropWidth / A4Ratio]);
    pdf.setPage(1);

    let i = 1;
    html2canvas(toPrint).then((canvas) => {
      const _canvas = canvas;

      const croppedCanvas = document.createElement("canvas");
      const croppedCanvasContext = croppedCanvas.getContext("2d");

      croppedCanvas.width = cropWidth;
      croppedCanvas.height = cropHeight;

      for (let i = 1; i <= nbPages; i++) {
        pdf.addPage();
      }

      for (let i = 1; i <= nbPages; i++) {
        pdf.setPage(i + 1);
        // Clear the canvas
        croppedCanvasContext.clearRect(0, 0, cropWidth, cropHeight);
        croppedCanvasContext.drawImage(
          _canvas,
          0,
          ((cropWidth * 2) / A4Ratio) * (i - 1),
          cropWidth * 2,
          (cropWidth * 2) / A4Ratio,
          0,
          0,
          cropWidth,
          cropHeight
        );
        pdf.addImage(
          croppedCanvas.toDataURL(),
          "JPEG",
          0,
          0,
          cropWidth,
          cropWidth / A4Ratio
        );
      }

      pdf.deletePage(1);

      pdf.save(`cde-${order.NUMCDE}.pdf`);
    });
  };

  const getPrice = (opt) => {
    const optionsPrices = qs.parse(order.CONFIG_Prix);

    const OptPrice = Object.keys(optionsPrices).find(
      (kateg) => kateg === opt.KATEG + "¤" + opt.SIFRA
    );

    const _price = optionsPrices[OptPrice];

    if (!(_price > 0)) return null;

    return price(_price);
  };

  const [cartoucheHaut, setCartoucheHaut] = useState("");
  const [cartoucheBas, setCartoucheBas] = useState("");
  useEffect(() => {
    if (print) {
      rtfToHTML.fromString(
        print.header,
        { template: RTFTemplate },
        (err, html) => setCartoucheHaut(html)
      );
      rtfToHTML.fromString(
        print.footer,
        { template: RTFTemplate },
        (err, html) => setCartoucheBas(html)
      );
    }
  }, [print]);

  const printableRef = useRef();

  const filteredStandardOptions = standardOptions?.options.filter(
    (stdOpt) => {
      return (
        !!selectedStdOpts[stdOpt.KATEG] &&
        String(stdOpt.SIFRA) ===
          String(selectedStdOpts[stdOpt.KATEG])
      );
    }).filter(opt => !order.article?.CONFIG_ListeOptions?.split(',')?.includes(String(opt.SIFRA)));
  

  const filteredSortedAdditionalOptions = additionalOptions?.options
    .filter((addOpt) => {
      return (
        !order?.CONFIG_PakListeOptions?.split(',')?.includes(addOpt.SIFRA) && 
        selectedAddOpts[addOpt.KATEG] &&
        selectedAddOpts[addOpt.KATEG]
          .split(",")
          .includes(String(addOpt.SIFRA))
      );
    }).filter(opt => !order?.article?.CONFIG_ListeOptions?.split(',')?.includes(String(opt.SIFRA)))
    .sort((a, b) => {
      if (a.KATEG < b.KATEG) return -1;
      if (a.KATEG > b.KATEG) return 1;
      return 0;
  });
  
  return (
    <React.Fragment>
      <Header user={user} />
      <SubHeader>
        <Box>Rapport</Box>
        {!isFetching && order && order.CDE_EN_NEGO === "O" && (
          <Box maxWidth={500}>
            <Alert severity="error" icon={<HourglassEmptyIcon />}>
              Cette commande est en cours de validation
            </Alert>
          </Box>
        )}
        <Box mt={1}>
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate.push("OrdersPage")}
          >
            Revenir à la liste
          </Button>
        </Box>
      </SubHeader>
      {/* {image && image.map(im => {
                return <img src={im} />;
            })} */}
      {isFetching ? (
        <Loader />
      ) : (
        <Box mt={2} mb={8}>
          <Container maxWidth="lg">
            <Box px={3}>
              <ReactToPrint
                documentTitle={`commande-${order.NUMCDE}`}
                trigger={() => {
                  // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                  // to the root node of the returned component as it will be overwritten.
                  return (
                    <Button
                      variant="outlined"
                      size="small"
                      startIcon={<PrintIcon />}
                    >
                      Demander l'impression
                    </Button>
                  );
                }}
                content={() => printableRef.current}
              />
            </Box>
            <Box
              id="order-to-print"
              ref={printableRef}
              maxWidth="790px"
              px={3}
              py={2}
            >
              <div>
                {cartoucheHaut && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mb={1}
                  >
                    <Box width={150} mr={5}>
                      <img alt={TITLE} width="100%" height="auto" src={LOGO} />
                    </Box>
                    <Box flex={1}>
                      <span
                        style={{ textAlign: "left" }}
                        dangerouslySetInnerHTML={{
                          __html: cartoucheHaut,
                        }}
                      ></span>
                    </Box>
                  </Box>
                )}
                <Box p={3} mt={1} className={classes.header}>
                  <Box display="flex" alignItems="center">
                    <Typography variant="h5" component="p">
                      {order.STATUT === "W"
                        ? `Pré-commande n°${order.NUMCDE}`
                        : `Confirmation de commande n°${order.NUMCDE}`}
                    </Typography>
                    {order.DESTINATION === "C" && (
                      <Box ml={2}>
                        <Chip
                          size="small"
                          label="Client"
                          variant="outlined"
                          color="primary"
                        />
                      </Box>
                    )}
                    {order.DESTINATION === "S" && (
                      <Box ml={2}>
                        <Chip
                          size="small"
                          label="Stock"
                          variant="outlined"
                          color="primary"
                        />
                      </Box>
                    )}
                  </Box>
                  <Box width="100%" display="flex">
                    <Box mr={2}>
                      <Typography variant="subtitle2" component="div">
                        Date
                      </Typography>
                      <Typography variant="body" component="p">
                        {order.DTECDE &&
                          order.DTECDE.cleanDate() &&
                          moment(order.DTECDE).format("DD/MM/YYYY")}
                      </Typography>
                    </Box>
                    <Box mr={2}>
                      <Typography variant="subtitle2" component="div">
                        Concessionnaire
                      </Typography>
                      <Typography variant="body" component="p">
                        {order.client.NOMCON}
                      </Typography>
                    </Box>
                    <Box mr={2}>
                      <Typography variant="subtitle2" component="div">
                        Responsable
                      </Typography>
                      <Typography variant="body" component="p">
                        {order.DONNEUR_ORDRE}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant="subtitle2" component="div">
                    Adresse de livraison
                  </Typography>
                  <Typography variant="body" component="p">
                    {order.LIEULIVSOUHAIT}
                    <br />
                    {order.ADLIV1} {order.ADLIV2}, {order.CPLIV} {order.VILLIV}
                  </Typography>
                  <Typography variant="subtitle2" component="div">
                    Date de livraison souhaitée
                  </Typography>
                  <Typography variant="body" component="p">
                    {order.DTELIVSOUHAIT.cleanDate() &&
                      moment(order.DTELIVSOUHAIT).format("DD/MM/YYYY")}
                  </Typography>
                  {/* <Box width="100%" display="flex">
                    <Box mr={2}>
                      <Typography variant="subtitle2" component="div">
                        Véhicule H.T
                      </Typography>
                      <Typography variant="body" component="p">
                        {price(order.TARIFARTCDE || 0)}
                      </Typography>
                    </Box>
                    <Box mr={2}>
                      <Typography variant="subtitle2" component="div">
                        Options H.T
                      </Typography>
                      <Typography variant="body" component="p">
                        {price(order.TARIFOPTCDE || 0)}
                      </Typography>
                    </Box>
                    <Box mr={2}>
                      <Typography variant="subtitle2" component="div">
                        Frais de transport H.T
                      </Typography>
                      <Typography variant="body" component="p">
                        {price(order.TARIFTRANSPORTCDE || 0)}
                      </Typography>
                    </Box>
                    <Box mr={2}>
                      <Typography variant="subtitle2" component="div">
                        Total H.T
                      </Typography>
                      <Typography variant="body" component="p">
                        {price(
                          (order.TARIFARTCDE || 0) +
                            (order.TARIFOPTCDE || 0) +
                            (order.TARIFTRANSPORTCDE || 0)
                        )}
                      </Typography>
                    </Box>
                  </Box> */}
                  {order.DESTINATION === "C" && (
                    <React.Fragment>
                      <Typography variant="subtitle2" component="div">
                        Nom du client
                      </Typography>
                      <Typography variant="body" component="p">
                        {order.CLIFNOM}
                      </Typography>
                      <Typography variant="subtitle2" component="div">
                        Adresse du client
                      </Typography>
                      <Typography variant="body" component="p">
                        {order.CLIFAD1} {order.CLIFAD2} {order.CLIFCP}{" "}
                        {order.CLIFVILLE}
                      </Typography>
                    </React.Fragment>
                  )}
                  <Box width="100%" display="flex">
                    <Box mr={2}>
                      <Typography variant="subtitle2" component="div">
                        N° de châssis
                      </Typography>
                      <Typography variant="body" component="p">
                        {order.NUMCHASSIS}
                      </Typography>
                    </Box>
                    {/* <Box mr={2}>
                      <Typography variant="subtitle2" component="div">
                        Période d'affectation
                      </Typography>
                      <Typography variant="body" component="p">
                        {order.DTELIVAFFECTE &&
                          order.DTELIVAFFECTE.cleanDate() &&
                          `${toFR(
                            moment(order.DTELIVAFFECTE).format("MMM")
                          )}. ${moment(order.DTELIVAFFECTE).format("YYYY")}`}
                      </Typography>
                    </Box> */}
                  </Box>
                </Box>
                {order.COMMENTAIRE && order.COMMENTAIRE !== "" && (
                  <Box py={1}>
                    <Alert severity="info">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: formatedText(order.COMMENTAIRE),
                        }}
                      ></span>
                    </Alert>
                  </Box>
                )}
                {/* <Box p={3}>
                                    <div style={{backgroundImage: 'url('+order.article.ADRIA_image+')', backgroundSize: 'contain', backgroundPosition: 'left', backgroundRepeat: 'no-repeat'}} className={classes.image} />
                                </Box> */}
                {/* Header */}
                <Box py={1}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="h6">
                      {order.article.ADRIA_name}
                    </Typography>
                    {/* <Typography variant='subtitle1'>{price(order.TARIFARTCDE||0)}€</Typography> */}
                  </Box>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Collection</TableCell>
                          <TableCell>Code</TableCell>
                          <TableCell>Model</TableCell>
                          <TableCell>Motorisation</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>{order.COLLECTION}</TableCell>
                          <TableCell>{order.ADRIA_code}</TableCell>
                          <TableCell>{`${order.ADRIA_model} ${order.ADRIA_packages_name}`}</TableCell>
                          <TableCell>{order.article.MO_LIBARTCON}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                {/* Packs */}
                {Boolean(packs.length) && <Box py={1}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="h6">
                      Pack(s)
                    </Typography>
                    {/* <Typography variant='subtitle1'>{price(order.TARIFARTCDE||0)}€</Typography> */}
                  </Box>
                  <TableContainer>
                    <Table size="small">
                      <TableBody>
                        {packs.map(pack => 
                          <TableRow id={pack.CODPAC}>
                            <TableCell>{pack.LIBPAC}</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>}
                {/* StandardOptions */}
                {Boolean(filteredStandardOptions.length) && (
                  <Box py={1}>
                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="h6">Options standards</Typography>
                      {/* <Typography variant='subtitle1'>{price()}€</Typography> */}
                    </Box>
                      <TableContainer>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>Catégorie</TableCell>
                              <TableCell>Code</TableCell>
                              <TableCell>Libellé</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredStandardOptions.map((opt) => (
                                <TableRow>
                                  <TableCell>{opt.KATE_NAZIV}</TableCell>
                                  <TableCell>{opt.SIFRA}</TableCell>
                                  <TableCell>
                                    {opt.LIBOPTCON || opt.NAZIV}
                                  </TableCell>
                                  {/* <TableCell align="right">
                                    {getPrice(opt)}
                                  </TableCell> */}
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                    </TableContainer>
                  </Box>
                )}
                {/* Packs */}
                {Boolean(order.CONFIG_Pak) && (
                  <Box py={1}>
                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="h6">Packs</Typography>
                    </Box>
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Nom</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {order.CONFIG_Pak?.split(',').map((pack) => (
                              <TableRow>
                                <TableCell>{pack}</TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
                {/* AdditionalOptions */}
                {Boolean(filteredSortedAdditionalOptions.length) && (
                  <Box py={1}>
                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="h6">Options additionnelles</Typography>
                    </Box>
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Catégorie</TableCell>
                            <TableCell>Code</TableCell>
                            <TableCell>Libellé</TableCell>
                            {/* <TableCell>Prix</TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredSortedAdditionalOptions.map((opt) => (
                              <TableRow>
                                <TableCell>{opt.KATE_NAZIV}</TableCell>
                                <TableCell>{opt.SIFRA}</TableCell>
                                <TableCell>
                                  {opt.LIBOPTCON || opt.NAZIV}
                                </TableCell>
                                {/* <TableCell align="right">
                                  {getPrice(opt)}
                                </TableCell> */}
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
                {cartoucheBas && (
                  <Box py={1}>
                    <Alert icon={false} severity="warning">
                      <span
                        style={{ textAlign: "left" }}
                        dangerouslySetInnerHTML={{
                          __html: cartoucheBas,
                        }}
                      ></span>
                    </Alert>
                  </Box>
                )}
              </div>
            </Box>
          </Container>
        </Box>
      )}
    </React.Fragment>
  );
}

export default OrderPage;
