import React, { useEffect, useState } from 'react'
import SubHeader from '../SubHeader'
import { Box, makeStyles, Grid, Container, Typography } from '@material-ui/core'
import { request } from '../../request';
import confs from '../../confs';
import { useSnackbar } from 'notistack';
import Loader from '../Loader';
import { frDate } from '../../utils';
import EmptyData from './EmptyData';

const { URL_API } = confs;

function CollectionStep({ nextStep, selectCollection, value }) {

    const [isFetching, setIsFetching] = useState(false);
    const [collections, setCollections] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    
    useEffect(() => {
        const fail = () => {
            setIsFetching(false);
            enqueueSnackbar('Erreur au chargement de la donnée.', { variant: 'error' });
        };
        // Get collections
        setIsFetching(true);
        request.get(`${URL_API}/api/collections`).then(res => {
            if (res.success) {
                setCollections(res.payload);
                setIsFetching(false);
            } else {
                fail();
            }
        }).catch(err => fail());
    }, []);

    const onSelectCollection = (col) => {
        selectCollection(col);
        nextStep();
    };
    
    return (
        <React.Fragment>
            <Box mt={-2}>
                <SubHeader>
                    Séléctionner une collection
                </SubHeader>
            </Box>
            <Box my={2}>

                {isFetching && (
                    <Loader />
                )}

                <Collections 
                    collections={collections} 
                    onSelectCollection={onSelectCollection} 
                    value={value}
                />

            </Box>
        </React.Fragment>
    );
}

const useCollectionStyles = makeStyles({
    root: {
        border: '3px solid #ccc',
        borderRadius: '.25rem',
        cursor: 'pointer',
        height: '100%',
    },
    date: {
        fontSize: '11px',
    }
});

const Collections = ({ collections, onSelectCollection }) => {

    const classes = useCollectionStyles();
    console.log(collections);
    return (
        <Container maxWidth='lg'>
            <Grid container spacing={2}>
                {collections.map(collection => (
                    <Grid item lg={2} md={3} sm={6} xs={12}>
                        <Box 
                            className={classes.root} 
                            px={1} py={2} 
                            onClick={() => onSelectCollection(collection.COLLECTION)}
                        >
                            <Typography variant='h5' align='center'>
                                {collection.COLLECTION}
                            </Typography>
                            <Typography className={classes.date} variant='subtitle2' align='center'>
                                {frDate(collection.DTEDEB)} - {frDate(collection.DTEFIN)}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}

export default CollectionStep
